import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../redux/actions/usersActions";
import { useNavigate } from "react-router-dom";
import { adminAllCart } from "../../redux/actions/cartAction";

const AdminViewAllCartHook = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem("user"));

    const storeIdRes = useSelector(
        (state) => state.allStores.selectStoreBySuperAdmin
      );

    useEffect(()=>{
        if(storeIdRes && user.role=="superAdmin") dispatch(adminAllCart(storeIdRes));
    },[storeIdRes]);

    useEffect(() => {
        let storeId=user.role=="superAdmin" ? storeIdRes :user.storeId;

        dispatch(adminAllCart(storeId));
    }, []);
    const allCarts = useSelector((state) => state.cartReducer.allCartAdmin);

    let carts = [];
    if (allCarts) carts = allCarts.data;

    // const clickHandler = (id) => {
    //     navigate(`/admin/edituser/${id}`);
    // };
    return [carts];
};

export default AdminViewAllCartHook;
