import { ALL_USERS, GET_ONE_USER, UPDATE_USER, ADD_USER_BY_ADMIN, GET_ONE_USER_TOKEN, ALL_VENDORS , SHOWSlELECTSTORECOMPONENT } from "../type";
const inital = {
  allUser: [],
  oneUser: [],
  oneUserToken: [],
  updateUser: [],
  addUser: [],
  allVendors:[],
  showSelectStoreComponent:false

};

const usersReducers = (state = inital, action) => {
  switch (action.type) {
    case ALL_USERS:
      return {
        ...state,
        allUser: action.payload,
      };
    case GET_ONE_USER:
      return {
        ...state,
        oneUser: action.payload,
      };
    case UPDATE_USER:
      return {
        ...state,
        updateUser: action.payload,
      };
    case GET_ONE_USER_TOKEN:
      return {
        ...state,
        oneUserToken: action.payload,
      };

    case ADD_USER_BY_ADMIN:
      return {
        ...state,
        addUser: action.payload
      }
      case ALL_VENDORS:
        return {
          ...state,
          allVendors: action.payload
        }
        case SHOWSlELECTSTORECOMPONENT:
        return{
          ...state,
          showSelectStoreComponent:true
        }
    default:
      return state;
  }
};

export default usersReducers;
