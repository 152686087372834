import { useSearchParams } from "react-router-dom";

export default function Filter({parameters}) {
  const[searchParams,setSearchParams]=useSearchParams();

  console.log('parameters');
  console.log(searchParams.get('status'));
  let search=searchParams.get('status')==null? 'all' : searchParams.get('status');

  function handleClick(status){
    console.log(status);
    setSearchParams({ status});
    // if(status!=='all')
    // {

    // }
    // else
    // {

    // }
  }

  return (
    <div className="d-flex my-3">
          <div className="d-flex align-items-center mx-2">فلتر حسب:</div>
          {
            parameters.map(el=><button key={el.text}
              onClick={()=>handleClick(el.status)} 
              className={`mx-1 filter ${search==el.status? `activeFilter` :''} `}>{el.text}</button>)
          }
        {/* <button className="mx-1 filter activeFilter">الكل</button>  
        <button className="mx-1 filter">نشط</button>
        <button className="mx-1 filter">غير نشط</button> */}
    </div>
  )
}
