import {
  GET_ALL_STORES,
  GET_ONE_STORE_BY_NAME,
  ONE_STORE,
  SELECT_STORE_BY_SUPER_ADMIN,
  UPDATE_STORE,
  iS_UPDATING,
  UPDATE_STORENAME
} from "../type";
import { useGetData  } from "../../hooks/useGetData";
import { useUpdateData } from "../../hooks/useUpdateData";
import {useInsertData} from "../../hooks/useInsertData";

export const getAllStores = (query) => async (dispatch) => {
  try {
    console.log(query);
    
    const response = await useGetData(`/api/v1/stores?${query}`);

    dispatch({
      type: GET_ALL_STORES,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_STORES,
      payload: error,
    });
  }
};

export const getOneStoreByName = (name) => async (dispatch) => {
  try {
    const response = await useGetData(`/api/v1/stores/one/${name}`);

    dispatch({
      type: GET_ONE_STORE_BY_NAME,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_ONE_STORE_BY_NAME,
      payload: error,
    });
  }
};

export const setStoreIdBySuperAdmin = (store) => async (dispatch) => {
  try {
    dispatch({
      type: SELECT_STORE_BY_SUPER_ADMIN,
      payload: store,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: SELECT_STORE_BY_SUPER_ADMIN,
      payload: error,
    });
  }
};

// get store by id
export const getStoreById = (id) => async (dispatch) => {
  try {
    const response = await useGetData(`/api/v1/stores/${id}`);

    dispatch({
      type: ONE_STORE,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: ONE_STORE,
      payload: error,
    });
  }
};

//update store status
export const updateStore=(id,body)=>async(dispatch)=>{
  await dispatch({type:iS_UPDATING});
  try {
    const response=await useUpdateData(`/api/v1/stores/${id}`,body);
    dispatch({
      type:UPDATE_STORE,
      payload:response
    });   
  } catch (error) {
    dispatch({
      type:UPDATE_STORE,
      payload:error.message
    });
  }
}

//update store name
export const updateStoreName=(body)=>async(dispatch)=>{
  try {
    const response=await useInsertData('/api/v1/stores/updatename',body);
    dispatch({
      type:UPDATE_STORENAME,
      payload:response
    });
  } catch (error) {
    dispatch({
      type:UPDATE_STORENAME,
      payload:error.message
    });
  }
}
