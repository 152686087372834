import ReactDOM from "react-dom/client";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./i18n";
import store from "./redux/store";
import { Provider, useDispatch, useSelector } from "react-redux";
import { HelmetProvider } from "react-helmet-async";
import { useEffect, useState } from "react";
import App from "./App";
import { Router } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));

//window.location.pathname('/login');

//console.log();

root.render(
  <HelmetProvider>
    <Provider store={store}>
      <App />
    </Provider>
  </HelmetProvider>
);
