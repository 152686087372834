import React from "react";
import AdminAllStoresHook from "../../hook/admin/AdminAllStoresHook";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import Cookies from "js-cookie";
import { ToastContainer } from "react-toastify";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { Button, Modal } from "react-bootstrap";
import AddButton from "../Utilities/AddButton";
import Filter from "../Utilities/Filter";
import moment from 'moment';

function AdminAllStores() {
  const [stores, settings,upDateStoreStatus,editModal,setEditModal,handleShowModal,storeName,setStoreName
    ,storeNameAr,setStoreNameAr,handleUpdateStoreName] = AdminAllStoresHook();
  const navigate = useNavigate();
  const { t } = useTranslation();

  console.log("stores");
  console.log(stores);

  let baseUrl = window.location.host;
  let user = localStorage.getItem('user');
  user=JSON.parse(user);

  console.log(baseUrl);

  const columns = [
    {
      name: t("Store"),
      selector: "name",
      sortable: true,
    },
    {
      name: t("StoreUrl"),
      cell: (row) => (
        <a href={"/" + row.name} target="_blank">
          {baseUrl + "/" + row.name}
        </a>
      ),
    },
    {
      name:'صفحة ادمن المتجر',
      cell:(row)=>(
        <Button variant="primary" onClick={()=>navigate(`/admin/edituser/${row.DEFAULT_VENDOR._id}`)}> صفحة ادمن المتجر</Button>
      )
    },
    {
      name:'تاريخ الانشاء',
      cell:(row)=>(
        <label>{moment(row.createdAt).format('YYYY-MM-DD _ HH:mm:ss')}</label>
      )
    },
    {
      name:'كود المتجر',
      selector:"refCode"
    },
    {
      name:'تعديل',
      cell:(row)=>(
        <Button
        variant="warning"
        onClick={()=>handleShowModal(row)}
        >
          تعديل
        </Button>
      )
    },
    {
      name: t("the status"),
      cell: (row) =>
        row.status === false ? (
          <Button
            onClick={()=>upDateStoreStatus({
              storeId:row._id,
              status:true
            })}
            variant="danger"
          >
            تنشيط
            <i className="fa fa-lock mx-1" />
          </Button>
        ) : (
          <Button
          onClick={()=>upDateStoreStatus({
              storeId:row._id,
              status:false
            })}
            variant="success"
          >
            حظر
            <i className="fa fa-unlock mx-1" />
          </Button>
        ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
    name:'حذف',
    cell:(row)=>  <Button
    onClick={()=>upDateStoreStatus({
      storeId:row._id,
      status:false,
      isDeleted:true
    })}
    variant="danger"
  >
    حذف
    <i className="fa fa-trash mx-1" />
  </Button>
    }
    
    
  ];

  const tableData = {
    columns,
    data: stores,
  };

  return (
    <div>
      <Helmet>
        <title>
          {` ${t("stores")} -  ${
            Cookies.get("i18next") === "ar"
              ? settings.data?.titleAr
              : settings.data?.titleEn
          }`}{" "}
        </title>
      </Helmet>

      <Modal show={editModal} onHide={()=>setEditModal(false)}>
      <Modal.Header>
          <Modal.Title>
            <div className="font">تعديل المتجر </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="font">
            <label className="mt-3 p-1">اسم المتجر(المميز)</label>
           <input value={storeName} onChange={(e)=>setStoreName(e.target.value)} className="form-control mt-2" />
           <label className="mt-3 p-1">اسم المتجر بالعربية</label>
           <input value={storeNameAr} onChange={(e)=>setStoreNameAr(e.target.value)} className="form-control mt-2" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="font"
            variant="secondary"
            onClick={()=>setEditModal(false)}
          >
            {t("retreat")}
          </Button>
          <Button className="font" variant="warning" onClick={()=>handleUpdateStoreName()} >
            تعديل
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="d-flex justify-content-between">
      <div className="admin-content-text">{t("StoresManagment")} </div>
        
      </div>

      <div className="d-flex justify-content-between">
       <Filter parameters={[
        {text:'الكل',status:'all'},
        {text:'نشط',status:'true'},
        {text:'غير نشط',status:'false'}
       ]} 
       />
        

        <AddButton title={t("AddStore")} navigateTo={`/admin/addstore`} />

      </div>
        

        
        
      
   

      <DataTableExtensions {...tableData} filterPlaceholder={t("search here")}>
        <DataTable
          noHeader
          defaultSortAsc={false}
          pagination
          highlightOnHover
        />
      </DataTableExtensions>

      <ToastContainer />
    </div>
  );
}

export default AdminAllStores;
