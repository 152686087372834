import React, { useEffect, useState } from "react";
import { createBrand } from "../../redux/actions/brandAction";
import { useDispatch, useSelector } from "react-redux";
import notify from "../../hook/useNotification";
import {
  deleteReviewProduct,
  updateReviewProduct,
} from "../../redux/actions/reviewActions";

const EditRateHook = (review) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [showEdit, setShowEdit] = useState(false);
  const [newRateText, setNewRateText] = useState(review.comment);
  const [newRateValue, setNewRateValue] = useState(review.rate);

  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = () => setShowEdit(true);

  const onChangeRateText = async (e) => {
    setNewRateText(e.target.value);
  };
  const onChangeRateValue = async (value) => {
    setNewRateValue(value);
  };

  const handleEdit = async () => {
    setLoading(true);
    await dispatch(
      updateReviewProduct(review._id, {
        product: review.product,
        user: review.user,
        rate: newRateValue,
        comment: newRateText,
      })
    );
    setLoading(false);
    handleCloseEdit();
  };

  const res = useSelector((state) => state.reviewReducer.updateReview);

  useEffect(() => {
    if (loading === false) {
      if (res) {
        if (res) {
          notify("تم تعديل التقييم بنجاح", "success");
          setTimeout(() => {
            window.location.reload(false);
          }, 1000);
        } else {
          notify("هناك مشكله فى عملية التعديل", "error");
        }
      }
    }
  }, [loading]);

  return [
    handleEdit,
    handleCloseEdit,
    showEdit,
    handleShowEdit,
    onChangeRateText,
    newRateText,
    onChangeRateValue,
    newRateValue,
  ];
};

export default EditRateHook;
