import AdminRefCodes from "../../Components/Admin/AdminRefCodes";
import SideBar from "../../Components/Admin/SideBar";

export default function AdminRefCodesPage() {
    return (
        <div className="d-flex justify-content-start">
            <div>
                <SideBar />
            </div>
            <div className="p-4" style={{ width: '100%' }}>
                <AdminRefCodes />
            </div>
        </div>
    )
}
