import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import ViewAllReviewHook from "../../hook/rate/ViewAllReviewHook";
import rate from "../../Images/rate.png";
import Pagination from "../Utilities/Pagination";
import RateItem from "./RateItem";
import RatePost from "./RatePost";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const RateContainer = ({ rateAv, reateQty }) => {
  const { id } = useParams();
  const { t } = useTranslation()
  const [allReviewByProd, onPress, showReviews] = ViewAllReviewHook(id);
  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  return (
    <Container className="rate-container">
      <Row className="mt-5">
        <Col className="d-flex">
          <div className="sub-tile d-inline p-1 " style={{ color: settingsRes?.data?.primaryColor }}>{t("Comments")}</div>
          {showReviews ? <> <img className="mt-2" src={rate} alt="" height="16px" width="16px" />
            <div className="cat-rate  d-inline  p-1 pt-2">
              {rateAv && rateAv.toFixed(1)}
            </div>
            <div className="rate-count d-inline p-1 pt-2">
              ({`${reateQty ? reateQty : 0} ${t("rating")}`})
            </div></> : <div></div>}

        </Col>
      </Row>
      <RatePost />
      {allReviewByProd.data ? (
        allReviewByProd.data.map((review) => {
          return <RateItem key={review._id} review={review} />;
        })
      ) : (
        <h3>{t("no comments")}</h3>
      )}
      {allReviewByProd.numberOfPages >= 2 ? (
        <Pagination
          pageCount={allReviewByProd ? allReviewByProd.numberOfPages : 0}
          onPress={onPress}
        />
      ) : null}
    </Container>
  );
};

export default RateContainer;
