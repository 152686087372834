import { useEffect, useRef } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getOneOffer, updateOffer } from "../../redux/actions/offersAction";
import notify from "../useNotification";
import getStoreNameFromUrl from "../../Components/Utilities/getStoreNameFromUrl";

const storeName=getStoreNameFromUrl();

const EditOfferHook = (id) => {
  const { t } = useTranslation();
  const startDateRef = useRef();
  const endDateRef = useRef();

  const [nameAr, setNameAr] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLaoding] = useState(true);


  var user = "";
  if (localStorage.getItem("user") != null) {
    user = JSON.parse(localStorage.getItem("user"));
  }

  const navigate = useNavigate();

  const onChangeNameAr = (e) => {
    setNameAr(e.target.value);
  };

  const onChangeNameEn = (e) => {
    setNameEn(e.target.value);
  };

  const onChangeStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const onChangeEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOneOffer(id));
  }, [dispatch, id]);

  const offerRes = useSelector((state) => state.offersReducers.oneOffer);

  useEffect(() => {
    if (offerRes) {
      setNameAr(offerRes?.nameAr);
      setNameEn(offerRes?.nameEn);
      setStartDate(offerRes?.startDate);
      setEndDate(offerRes?.endDate);
    }
  }, [offerRes]);

  const onSubmit = async () => {
    if (nameAr === "" || nameEn === "" || startDate === "" || endDate === "") {
      notify(t("please complete the data"), "warn");
      return;
    } else {
      setLaoding(true);
      await dispatch(
        updateOffer(id, {
          nameAr: nameAr,
          nameEn: nameEn,
          startDate: startDate,
          endDate: endDate,
        })
      );
      setLaoding(false);
    }
  };

  const editOfferResponse = useSelector(
    (state) => state.offersReducers.updateOffer
  );


   //get settings
   const settingsRes = useSelector((state) => state.settingsReducers.settings);


  useEffect(() => {
    if (loading === false) {
      if (editOfferResponse) {
        notify(t("Modified successfully"), "success");
        setTimeout(() => {

             if(user?.role === 'vendor'){
                navigate(`/vendor/offers`)
              }else {
                navigate(`/admin/offers`)
              }
        }, 1500);
      }
    }
  }, [loading, editOfferResponse, t, navigate]);

  return [
    nameAr,
    nameEn,
    startDate,
    endDate,
    onChangeNameAr,
    onChangeNameEn,
    onChangeStartDate,
    onChangeEndDate,
    onSubmit,
    settingsRes
  ];
};

export default EditOfferHook;
