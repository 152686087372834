import {
    GET_CATEGORIES_FROM_DROP_SHIPPING,
    GET_PRODUCTS_FOR_ONE_CATEGORY_FROM_DROP_SHIPPING
  } from "../type";
  
  const inital = {
     allDropShippingCategories: [],
     allDropShippngProductsForCategory: []
  };

  const exportDropShippingReducer=(state=inital,action)=>{
      switch(action.type)
      {
        case GET_CATEGORIES_FROM_DROP_SHIPPING:
            return{
                ...state,
                allDropShippingCategories:action.payload
            };
        case GET_PRODUCTS_FOR_ONE_CATEGORY_FROM_DROP_SHIPPING:
            return{
                ...state,
                allDropShippngProductsForCategory:action.payload
            };
            
        default:
            return state;
      }
  }

  export default exportDropShippingReducer;