import React, { useEffect, useState } from "react";
import { createBrand } from "../../redux/actions/brandAction";
import { useDispatch, useSelector } from "react-redux";
import notify from "../../hook/useNotification";
import { createReview } from "../../redux/actions/reviewActions";
import {  getSettings} from "../../redux/actions/settingsAcions"

const AddRateHook = (id) => {
  const dispatch = useDispatch();
  const [rateText, setRateText] = useState("");
  const [rateValue, setRateValue] = useState(0);

  const [loading, setLoading] = useState(true);

  const onChangeRateText = (e) => {
    setRateText(e.target.value);
  };
  const onChangeRateValue = (val) => {
    setRateValue(val);
  };
  var user = "";
  if (localStorage.getItem("user") != null) {
    user = JSON.parse(localStorage.getItem("user"));
  }

  const onSubmit = async () => {
    if (user == "") {
      notify("من فضلك سجل اولا", "error");
      return;
    }
    // if (rateText === "") {
    //   notify("من فضلك اكتب تعليق", "error");
    //   return;
    // }
    setLoading(true);
    await dispatch(
      createReview({
        product: id,
        user: user._id,
        rate: rateValue,
        comment: rateText,
      })
    );
    setLoading(false);
  };
  const res = useSelector((state) => state.reviewReducer.createReview);

  useEffect(() => {
    if (loading === false) {
      if (res) {
        notify("تم اضافة التقييم بنجاح", "success");
        setRateValue(0);
        setRateText("");
        setTimeout(() => {
          window.location.reload(false);
        }, 1000);
      }
    }
  }, [loading]);

  const settings = useSelector((state) => state.settingsReducers.settings);
  // console.log('sssssssettingsssssssss')
  const showReviews = settings?settings.data?.showReviews:''
  // console.log(showReviews)


  return [
    rateText,
    rateValue,
    onChangeRateText,
    onChangeRateValue,
    user,
    onSubmit,
    showReviews
  ];
};

export default AddRateHook;
