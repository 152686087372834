import React, { useEffect, useState } from "react";

import favoff from "../../Images/fav-off.png";
import favon from "../../Images/fav-on.png";
import { useDispatch, useSelector } from "react-redux";
import { getAllVendors } from "../../redux/actions/usersActions";

import {
  getAllDropShippingCategories,
  getAllDropShippingProductsForOneCategory,
} from "../../redux/actions/exportDropShippingActions";
import notify from "../../hook/useNotification";
import { useTranslation } from "react-i18next";

//for test
import { getAllCategory } from "../../redux/actions/categoryAction";
import { getAllProductsSearch } from "../../redux/actions/productsAction";
import { getProductsByCategories } from "../../redux/actions/productsAction";

const ExportProductsFromDropShippingHook = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [loadingUpdate, setLoadingUpdate] = useState(true);
  const user = JSON.parse(localStorage.getItem("user"));
  const settingsRes = useSelector((state) => state.settingsReducers.settings);
  const webSiteData = useSelector(
    (state) => state.settingsReducers.webSiteData
  );

  useEffect(() => {
    if (user?.role === "admin" || user?.role === "superAdmin")
      dispatch(getAllVendors());

    dispatch(getAllCategory());
  }, []);

  const allVendorsResponse = useSelector(
    (state) => state.usersReducers.allVendors
  );
  const category = useSelector((state) => state.allCategory.category);
  //console.log(allVendorsResponse);

  const [values, setValues] = useState({
    mainCategory: "",
    selectedVendor: "",
    show: false,
  });

  const onChangeCategory = (value) => {
    if (value) setValues({ ...values, ["mainCategory"]: value._id });
  };
  const onChangeValues = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  const getProductsFromDropShippingByCategory = async () => {
    if (values.mainCategory == "")
      return notify(
        t("Please choose the main category of the product"),
        "warn"
      );

    console.log("values.mainCategory");
    console.log(values.mainCategory);

    if (user?.role === "admin" || user?.role === "superAdmin") {
      if (values.selectedVendor == "0" || values.selectedVendor == "")
        return notify(t("select vendor name"), "warn");

      console.log(values.selectedVendor);
    }

    values.show = true;
    // get all categories for product
    await dispatch(
      getAllProductsSearch(`limit=30&page=1&categories=${values.mainCategory}`)
    );

    setLoading(false);
  };

  //const product = useSelector((state) => state.allProduct.product);
  // const product = useSelector(
  //   (state) => state.allProduct.productsByCategory
  // );

  // const product = useSelector(
  //   (state) => console.log(state)
  // );
  const allProductsCategory = useSelector(
    (state) => state.allProduct.allProducts
  );
  //if()
  //useEffect();
  console.log("product");
  console.log(allProductsCategory);

  const onSubmit = async () => {};

  return [
    values,
    onChangeValues,
    user,
    allVendorsResponse,
    settingsRes,
    webSiteData,
    category,
    getProductsFromDropShippingByCategory,
    onChangeCategory,
    allProductsCategory,
    onSubmit,
  ];
};

export default ExportProductsFromDropShippingHook;
