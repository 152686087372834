import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import notify from "../../hook/useNotification";
import { createNewUser } from "../../redux/actions/authActions";

const RegisterHook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(true);

  const settingsRes = useSelector((state) => state.settingsReducers.settings);
  const storeNameResponse = useSelector((state) => state.allStores.oneStore);

  console.log('storeNameResponse');
  console.log(storeNameResponse);

  const onChangeFirstname = (e) => {
    setFirstname(e.target.value);
  };
  const onChangeLastname = (e) => {
    setLastname(e.target.value);
  };
  const onChangeMobile = (e) => {
    setMobile(e.target.value);
  };
  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };
  const onChangeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };
  // const validationValues = () => {};
  const res = useSelector((state) => state.authReducers.createUser);

  const onSubmit = async () => {
    if (firstname === "") {
      notify("من فضلك ادخل الاسم الاول", "error");
      return;
    }
    if (lastname === "") {
      notify("من فضلك ادخل الاسم الاخير", "error");
      return;
    }
    if (mobile.length <= 9) {
      notify("من فضلك ادخل رقم الجوال صحيح", "error");
      return;
    }
    if (email === "") {
      notify("من فضلك ادخل البريد الالكتروني", "error");
      return;
    }
    if (password === "") {
      notify("من فضلك ادخل كلمة المرور", "error");
      return;
    }
    if (confirmPassword === "") {
      notify("من فضلك ادخل تاكيد كلمة المرور", "error");
      return;
    }
    if (password !== confirmPassword) {
      notify("من فضلك تاكد من كلمه المرور", "error");
      return;
    } else {


      setLoading(true);

      console.log('settingsRes.titleAr');
      console.log(settingsRes.data.titleAr);


      await dispatch(
        createNewUser({
          storeName:storeNameResponse.name,
          firstname: firstname,
          lastname: lastname,
          mobile: mobile,
          email: email.toLocaleLowerCase(),
          password: password
        })
      );

      
      setLoading(false);
    }
  };
  useEffect(() => {
    if (loading === false) {
      if (res) {
        if (res.token) {
          localStorage.setItem("token", res.token);
          notify("تم تسجيل الحساب بنجاح", "success");
          // setLoading(true);
          setTimeout(() => {
            navigate("/login");
          }, 2000);
        }
        if (res.data) {
          if (res.data.msg === "email or mobile already exists") {
            notify(" البريد الالكتروني أو رقم الجوال موجودين بالفعل", "error");
            
          }
        }
      }
    }
  }, [loading]);

  return [
    firstname,
    lastname,
    mobile,
    email,
    password,
    confirmPassword,
    loading,
    onChangeFirstname,
    onChangeLastname,
    onChangeMobile,
    onChangeEmail,
    onChangePassword,
    onChangeConfirmPassword,
    onSubmit,
    settingsRes
  ];
};

export default RegisterHook;
