import React, { useEffect, useState, useRef } from "react";
import Multiselect from "multiselect-react-dropdown";
import avatar from "../../Images/avatar.png";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCategory,
  getAllCategoriesWithoutBlockedCategory,
} from "../../redux/actions/categoryAction";
import { getAllBrand } from "../../redux/actions/brandAction";
import { subCategoryByCatId } from "../../redux/actions/subCategoryAction";
import { createProduct } from "../../redux/actions/productsAction";
import { getAllVendors } from "../../redux/actions/usersActions";
import {
  getWebsiteData,
  getSettings,
} from "../../redux/actions/settingsAcions";

import notify from "../../hook/useNotification";
import { useTranslation } from "react-i18next";
import { getAllStores } from "../../redux/actions/storesActions";

const AddProductHook = () => {
  const [images, setImages] = useState([]);
  const [nameAr, setNameAr] = useState("");
  const [nameEn, setNameEn] = useState("");

  const [weight, setWeight] = useState("");

  const [descriptionAr, setDescriptionAr] = useState("");
  const [descriptionEn, setDescriptionEn] = useState("");
  const [richDescriptionAr, setRichDescriptionAr] = useState("");
  const [richDescriptionEn, setRichDescriptionEn] = useState("");
  const [price1, setPrice1] = useState("");
  const [price2, setPrice2] = useState("السعر الاضافي");
  const [price3, setPrice3] = useState("السعر الاضافي");
  const [price4, setPrice4] = useState("السعر الاضافي");
  const [countInStock, setCountInStock] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [selectedsubId, setSelectedsubId] = useState("");
  const [isFeatured, setIsFeatured] = useState(false);
  const [brandId, setBrandId] = useState("");
  const [vedorId, setvedorId] = useState("");
  const [ProductStatus, setProductStatus] = useState(true);
  const [descriptionProduct, setDescriptionProduct] = useState("");
  const [keywordsProducts, setKeywordsProducts] = useState("");
  const [video, setVideo] = useState("");
  const [value, setValue] = useState("");
  const [tableSizes, settableSizes] = useState([]);
  const [showPicker, setShowPicker] = useState(false);
  const [colors, setColors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isPress, setIsPress] = useState(false);
  const [selectedStore, setSelectedStore] = useState("");

  const { t } = useTranslation();

  const onChangeVendor = (e) => {
    setvedorId(e.target.value);
  };
  const onChangeVideo = (e) => {
    setVideo(e.target.value);
  };
  const onChangeWeight = (e) => {
    setWeight(e.target.value);
  };
  const onChangeNameAr = (e) => {
    setNameAr(e.target.value);
  };
  const onChangeNameEn = (e) => {
    setNameEn(e.target.value);
  };
  const onChangeDescriptionAr = (e) => {
    setDescriptionAr(e.target.value);
  };
  const onChangeDescriptionEn = (e) => {
    setDescriptionEn(e.target.value);
  };
  const onChangeRichDescriptionAr = (e) => {
    setRichDescriptionAr(e.target.value);
  };
  const onChangeRichDescriptionEn = (e) => {
    setRichDescriptionEn(e.target.value);
  };
  const onChangePrice1 = (e) => {
    setPrice1(e.target.value);
  };
  const onChangePrice2 = (e) => {
    setPrice2(e.target.value);
  };
  const onChangePrice3 = (e) => {
    setPrice3(e.target.value);
  };
  const onChangePrice4 = (e) => {
    setPrice4(e.target.value);
  };
  const onChangeCountInStock = (e) => {
    setCountInStock(e.target.value);
  };

  const onChangeProductStatus = (e) => {
    e.persist();
    setProductStatus(e.target.value);
  };

  const onChangeDescriptionProduct = (e) => {
    e.persist();
    setDescriptionProduct(e.target.value);
  };

  const onChangeKeywordsProducts = (e) => {
    e.persist();
    setKeywordsProducts(e.target.value);
  };

  const onChangeValue = (e) => {
    e.persist();
    setValue(e.target.value);
  };

  //on change store
  const changeStore = async(e) => {
    console.log("e.target.value");
    console.log(e.target.value);
    const x= allStoresResponse.find(el=>el._id==e.target.value);
    console.log('selected..........');
    console.log(x);
    setSelectedStore(x);

    x.MULTI_VENDOR==false && setvedorId(x.DEFAULT_VENDOR);

    await dispatch(getAllCategory(undefined,e.target.value));
    await  dispatch(getAllBrand(undefined,e.target.value));

   

    //await dispatch(getSettings(e.target.value));

  };

  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));
  const ref = useRef(null);

  console.log("user.storeId");
  console.log(user);
  //all store response
  const allStoresResponse = useSelector((state) => state.allStores.allStores);
  const storeNameResponse = useSelector((state) => state.allStores.oneStore);
  const storeIdRes = useSelector(
    (state) => state.allStores.selectStoreBySuperAdmin
  );
  useEffect(() => {
    // const get = async () => {
    //   await dispatch(getAllCategory());
    //   // await dispatch(getAllCategoriesWithoutBlockedCategory());
    //   await dispatch(getAllBrand());
    // };
    // get();
    if(user.role!=="superAdmin"){
      if(user.storeId) dispatch(getAllCategory(undefined,user.storeId));
      if(user.storeId) dispatch(getAllBrand(undefined,user.storeId));
      console.log("user.storeId.MULTI_VENDOR");
      console.log(user.storeId.MULTI_VENDOR);

      if(storeNameResponse?.MULTI_VENDOR==false) setvedorId(storeNameResponse?.DEFAULT_VENDOR);
    }

    if(user.role=="superAdmin"){
      dispatch(getAllStores());
    }
    
  }, []);

  useEffect(() => {
    // if(user?.role==="vendor")
    if (user?.role === "admin" || user?.role === "superAdmin") {
      if (user?.role === "superAdmin" && selectedStore !== "") {
        dispatch(getAllVendors(selectedStore._id));
      } else {
        dispatch(getAllVendors(user?.storeId));
      }
    }
  }, [dispatch, selectedStore, user?.role, user?.storeId]);

  const category = useSelector((state) => state.allCategory.category);
  const categoryWithout = useSelector(
    (state) => state.allCategory.categoriesWithoutBlocked
  );
  const brand = useSelector((state) => state.allBrand.brand);
  const subCategoryByCat = useSelector(
    (state) => state.subCategory.subCategoryByCat
  );
  const allVendorsResponse = useSelector(
    (state) => state.usersReducers.allVendors
  );
  const webSiteData = useSelector(
    (state) => state.settingsReducers.webSiteData
  );

  // console.log('webSiteData');
  // console.log(webSiteData);

  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  useEffect(() => {
    if (webSiteData && webSiteData.MULTI_VENDOR == "false") {
      setvedorId(webSiteData.DEFAULT_VENDOR);
    }
  }, [webSiteData]);

  //storeNameResponse?.MULTI_VENDOR==true

  // useEffect(()=>{

  // },[storeNameResponse]);

  useEffect(() => {
    if (user && user.role === "vendor") {
      setvedorId(user._id);
    }
  }, [user]);

  // useEffect(()=>{
  //   if(vedorId){
  //     console.log('--------ssssssssss--------------')
  //     console.log( vedorId )
  //     console.log('---------ssssssssss-------------')
  //   }
  // },[vedorId])

  // show color picker

  const onSelectCategory = async (value) => {
    if (value && value._id !== 0 && value._id !== "0") {
      setCategoryId(value._id);
      await dispatch(subCategoryByCatId(value._id));
    }
  };
  const onSelectSubCategory = (value) => {
    if (value && value._id !== 0 && value._id !== "0") {
      setSelectedsubId(value._id);
    }
  };
  const onChangePicker = (e) => {
    setShowPicker(!showPicker);
  };

  useEffect(() => {
    if (categoryId !== 0) {
      if (subCategoryByCat) {
        setSubCategory(subCategoryByCat);
      }
    }
  }, [categoryId, subCategoryByCat]);

  const selectedFeatured = (e) => {
    setIsFeatured(e.target.value);
  };
  const onSelectBrand = (e) => {
    setBrandId(e.target.value);
  };
  const handleChaneComplete = (color) => {
    setColors([...colors, color.hex]);
    setShowPicker(!showPicker);
  };
  const removeColor = (color) => {
    const newColorArray = colors.filter((e) => e !== color);
    setColors(newColorArray);
  };

  //to convert base 64 to file
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const itemImages = Array.from(Array(Object.keys(images).length).keys()).map(
    (item, index) => {
      return dataURLtoFile(images[index], Math.random() + ".png");
    }
  );

  // add sizes of products to table

  const clickHandler = () => {
    if (value === "") {
      return notify(
        t("Please enter the available size of the product"),
        "warn"
      );
    }
    settableSizes([...tableSizes, value]);
    setValue("");
  };

  const deleteElement = (idx) => {
    settableSizes(tableSizes.filter((el, index) => index !== idx));
  };

  console.log('selectedStore');
  console.log(selectedStore);

  // console.log('storeIdRes');
  // console.log(storeIdRes);

  const handleSubmit = async (e) => {
    // console.log('descriptionEn')
    // console.log(descriptionEn)

    console.log("categoryId , subCategory");
    console.log(categoryId, selectedsubId);

    setIsPress(true);
    e.preventDefault();
    if (nameAr === "") {
      setIsPress(false);
      return notify(
        t("Please enter the name of the product in Arabic"),
        "warn"
      );
    }
    if (nameEn === "") {
      setIsPress(false);
      return notify(t("Please enter the product name in English"), "warn");
    }
    if (categoryId === 0 || categoryId === "" || categoryId === "0") {
      setIsPress(false);
      return notify(
        t("Please choose the main category of the product"),
        "warn"
      );
    }
    if (subCategory === 0 || subCategory === "" || subCategory.length === 0) {
      setIsPress(false);
      return notify(t("Please choose another main product category"), "warn");
    }
    if (selectedsubId === 0 || selectedsubId === "" || selectedsubId === "0") {
      setIsPress(false);
      return notify(t("Please select a product subcategory"), "warn");
    }
    if (countInStock <= 0 || countInStock === "") {
      setIsPress(false);
      return notify(
        t("Determine the available quantity of the product in stock"),
        "warn"
      );
    }
    if (price1 <= 0 || price1 === "") {
      setIsPress(false);
      return notify(t("Please add a price to the product"), "warn");
    }
    if (weight <= 0 || weight === "") {
      setIsPress(false);
      return notify(t("Please add the weight of the product"), "warn");
    }
    if (descriptionAr === "") {
      setIsPress(false);
      return notify(
        t("Please enter a description of the product in Arabic"),
        "warn"
      );
    }
    if (descriptionEn === "") {
      setIsPress(false);
      return notify(
        t("Please enter a description of the product in English"),
        "warn"
      );
    }
    if (user?.role === "superAdmin" && selectedStore === "") {
      notify("من فضلك تأكد من اختيار المتجر", "error");
      setIsPress(false);
      return;
    }

    console.log('vedorId',vedorId);

    if (
      (user?.role === "admin" || user.role === "superAdmin") &&
      (vedorId === "" || vedorId === 0 || vedorId === "0")
      // &&storeNameResponse?.MULTI_VENDOR==true
      // &&selectedStore.MULTI_VENDOR==true
    ) {
      setIsPress(false);
      return notify(t("select vendor name"), "warn");
    }
    if (images.length <= 0) {
      setIsPress(false);
      return notify(t("Please add Image for product"), "warn");
    }

    // console.log(brandId)
    const formData = new FormData();
    formData.append("nameAr", nameAr);
    formData.append("weight", weight);
    formData.append("nameEn", nameEn);
    formData.append("descriptionAr", descriptionAr);
    formData.append("descriptionEn", descriptionEn);
    formData.append("richDescriptionAr", richDescriptionAr);
    formData.append("richDescriptionEn", richDescriptionEn);
    formData.append("price1", price1);
    price2 !== "السعر الاضافي" && formData.append("price2", price2);
    price3 !== "السعر الاضافي" && formData.append("price3", price3);
    price4 !== "السعر الاضافي" && formData.append("price4", price4);
    // formData.append("price3", price3);
    // formData.append("price4", price4);
    formData.append("countInStock", countInStock);
    formData.append("category", categoryId);
    formData.append("subCategory", selectedsubId);
    formData.append("isFeatured", isFeatured);
    if (brandId !== "" && brandId !== "0" && brandId !== 0)
      formData.append("brand", brandId);
    formData.append("vendor",storeNameResponse?.MULTI_VENDOR==true? vedorId :user?._id);
    formData.append("status", ProductStatus);
    formData.append("descriptionProduct", descriptionProduct);
    formData.append("keywordsProducts", keywordsProducts);
    formData.append("video", video);
    formData.append("storeId", user.role=="superAdmin"? selectedStore._id  :user?.storeId);

    colors.map((color) => formData.append("colors", color));
    itemImages.map((item) => formData.append("images", item));
    tableSizes.map((size) => formData.append("classifications", size));
    ref.current.continuousStart();
    await dispatch(createProduct(formData));
    setLoading(false);
    setIsPress(false);
  };

  const product = useSelector((state) => state.allProduct.product);
  useEffect(() => {
    if (loading === false) {
      setImages([]);
      setVideo("");
      setNameAr("");
      setNameEn("");
      setWeight("");
      setDescriptionAr("");
      setDescriptionEn("");
      setRichDescriptionAr("");
      setRichDescriptionEn("");
      setPrice1("السعر");
      setPrice2("السعر الاضافي");
      setPrice3("السعر الاضافي");
      setPrice4("السعر الاضافي");
      setCountInStock("الكمية");
      setCategoryId(0);
      setSubCategory(0);
      setSelectedsubId("");
      setIsFeatured(false);
      setBrandId(0);
      setvedorId(0);
      setProductStatus("");
      setDescriptionProduct("");
      setKeywordsProducts("");
      setColors([]);
      // setTimeout(() => setLoading(true), 1500);
      if (product) {
        if (product.status === 201) {
          ref.current.complete();
          return notify("تم الاضافة بنجاح", "success");
        } else {
          ref.current.complete();
          return notify("فشل في عملية الاضافه", "error");
        }
      }
    }
  }, [loading, product]);
  return [
    subCategory,
    images,
    nameAr,
    nameEn,
    descriptionAr,
    descriptionEn,
    richDescriptionAr,
    richDescriptionEn,
    price1,
    price2,
    price3,
    price4,
    countInStock,
    showPicker,
    colors,
    category,
    brand,
    onSelectSubCategory,
    selectedFeatured,
    onSelectBrand,
    handleChaneComplete,
    onSelectCategory,
    removeColor,
    handleSubmit,
    setImages,
    onChangeNameAr,
    onChangeNameEn,
    onChangeDescriptionAr,
    onChangeDescriptionEn,
    onChangeRichDescriptionAr,
    onChangeRichDescriptionEn,
    onChangePrice1,
    onChangePrice2,
    onChangePrice3,
    onChangePrice4,
    onChangeCountInStock,
    onChangePicker,
    loading,
    allVendorsResponse,
    onChangeVendor,
    vedorId,
    webSiteData,
    ProductStatus,
    onChangeProductStatus,
    descriptionProduct,
    keywordsProducts,
    onChangeDescriptionProduct,
    onChangeKeywordsProducts,
    settingsRes,
    categoryWithout,
    weight,
    onChangeWeight,
    user,
    isPress,
    ref,
    onChangeVideo,
    video,
    value,
    tableSizes,
    onChangeValue,
    clickHandler,
    deleteElement,
    allStoresResponse,
    changeStore,
    selectedStore
  ];
};

export default AddProductHook;
