
export default function showComponentOrSelectStore(showSelectStoreComponent , storeIdRes) {
    if(showSelectStoreComponent==false){
       return "true";
      }
      else{
        console.log('ddddddddddddd');
        if(storeIdRes)
        return "true";
      }
}
