import React, { useEffect, useState } from "react";
import { Button, Container, Modal, Row, Spinner } from "react-bootstrap";
import DataTable from "react-data-table-component";
import ViewAllUsersHook from "../../hook/admin/ViewAllUsersHook";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import AdminEditUserHook from "../../hook/admin/AdminEditUserHook";
import { updateUser } from "../../redux/actions/usersActions";
import { useDispatch, useSelector } from "react-redux";
import notify from "../../hook/useNotification";
import { ToastContainer } from "react-toastify";
import AdminViewAllCartHook from "../../hook/admin/AdminViewAllCartHook";
import { Link, useNavigate } from "react-router-dom";
import { sendEmail } from "../../redux/actions/cartAction";
import AdminEditUser from "./AdminEditUser";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import getStoreNameFromUrl from "../Utilities/getStoreNameFromUrl";
import SuperAdminSelectStoreComponent from "../Utilities/SuperAdminSelectStoreComponent";

const storeName=getStoreNameFromUrl();

const AdminCartReport = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [carts] = AdminViewAllCartHook();

    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [showSelectStoreComponent, setShowSelectStoreComponent] =
    useState(false);

    const user = JSON.parse(localStorage.getItem("user"));

    useEffect(()=>{
        if(user.role==="superAdmin") setShowSelectStoreComponent(true);
    },[]);
    
    const handleClose = () => {
        setShow(false)
    }
    const [subject, setSubject] = useState('');
    const [text, setText] = useState('');
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(true);
   

    const onChangeSubject = (e) => {
        e.persist();
        setSubject(e.target.value);
    };
    const onChangeText = (e) => {
        e.persist();
        setText(e.target.value);
    };

    const handleSendEmail = async () => {
        if (subject === "" || text === "") {
            notify(t("please complete the data"), "warn");
            return;
        } else {
            setLoading(true);
            await dispatch(sendEmail({
                subject,
                email,
                text
            }))
            setLoading(false);
            setShow(false)
        }
    }
    const res = useSelector((state) => state.cartReducer.sendEmail);

    useEffect(() => {
        if (loading === false) {
            setEmail("");
            setSubject("");
            setText("");
            if (res && res.success === true) {
                notify(t("Email sent successfully"), "success");
            }
        }
    }, [loading]);

    const columns = [
        {
            name:t("first name"),
            sortable: true,
            cell: (row) => (
                <span
                    variant="secondary"
                    onClick={() => navigate(`/admin/editUser/${row._id}`)}
                    id={row._id}
                    style={{
                        fontSize: '14px',
                        cursor: 'pointer',
                        textDecoration: 'underline'
                    }}
                >
                    {row.firstname}
                </span >
            ),
        },
        {
            name:t("last name"),
            selector: "lastname",
            sortable: true,
        },
        {
            name: t("phone"),
            selector: "mobile",
            sortable: true,
        },
        {
            name: t("number of products"),
            selector: "count",
            sortable: true,
        },
        {
            name: t("total"),
            selector: "total",
            sortable: true,
        },
        {
            name: t("details"),
            cell: (row) => (
                <Button
                    variant="secondary"
                    onClick={() => navigate(`/admin/cartReportDetails/${row._id}`)}
                    id={row._id}
                    style={{
                        fontSize: '14px'
                    }}
                >
                    {t("products")}
                </Button >
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        }, {
            name:t("send email"),
            cell: (row) => (
                <Button
                    variant="success"
                    onClick={() => {
                        setShow(true)
                        setEmail(row.email)
                    }}
                    id={row._id}
                    style={{
                        fontSize: '14px'
                    }}
                >
                {t("send email")}
                </Button >
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const tableData = {
        columns,
        data: carts,
    };

    return (
        <div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>
                        <div className="font">{t("send emails")} </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="font">
                        <input
                            value={subject}
                            onChange={onChangeSubject}
                            type="text"
                            className="input-form d-block mt-3 px-3"
                            placeholder={t("address")}
                        />
                        <textarea
                            value={text}
                            onChange={onChangeText}
                            className="input-form-area p-2 mt-3"
                            rows="4"
                            cols="50"
                            placeholder={t("details")}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="font" variant="secondary" onClick={handleClose}>
                        {t("retreat")}
                    </Button>
                    <Button className="font" variant="success" onClick={handleSendEmail}>
                        {t("send email")}
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className="d-flex justify-content-between">
                <div className="admin-content-text">{t("Abandoned Basket Management")}</div>
            </div>

            {showSelectStoreComponent === true && <SuperAdminSelectStoreComponent />}

            <DataTableExtensions {...tableData} filterPlaceholder={t("search here")}>
                <DataTable
                    noHeader
                    defaultSortAsc={false}
                    pagination
                    highlightOnHover
                />
            </DataTableExtensions>
            <ToastContainer />
        </div>
    );
};
export default AdminCartReport;
