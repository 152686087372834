import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Link, useParams } from "react-router-dom";
import AddToCartHook from "../../hook/cart/AddToCartHook";
import ViewProductDetails from "../../hook/product/ViewProductDetailsHooks";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import LoadingBar from "react-top-loading-bar";

const ProductDescription = () => {
  const { id } = useParams();
  const [oneProduct, images, prod , productItem] = ViewProductDetails(id);
  const [    colorClick,
    indexColor,
    handleAddToCart,
    webSiteData,
    showReviews,
    onClickProduct,
    show,
    handleClose,
    sizeClick,
    sizeIndex,
    addProductColorSizeToCart] =
    AddToCartHook(id, oneProduct);
  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  const { t } = useTranslation();
  const currencytype = Cookies.get("i18next") === "ar" ? settingsRes.data?.defaultCurrancy.ar : settingsRes.data?.defaultCurrancy.en

console.log('settingsRes?.data?.showProductDescriptionTabs')
console.log(settingsRes?.data?.showProductDescriptionTabs)


  return (

    <>
    {
     
    
      productItem !== ''  && 
      <Modal show={show} onHide={handleClose} className="sizeModal">
      <Modal.Header>
        <Modal.Title>
          <div className="font">{t("Colors and sizes")}</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          oneProduct.colors.length >= 1 && (
          <>
            <div className="file-input-label-text font">
              {t("Available colors")}
            </div>
            <div md="12" className="mt-1 d-flex flex-wrap">
              {oneProduct.colors
                ? oneProduct.colors.map((el, index) => (
                    <div
                      key={index}
                      onClick={() => colorClick(index, el)}
                      className="color ms-2 d-flex"
                      style={{
                        backgroundColor: el,
                        border:
                          indexColor === index ? "2px solid black" : null,
                        width: "20px",
                        height: "20px",
                        boxShadow: "rgb(151 151 151) 0px 4px 4px 0px",
                      }}
                    ></div>
                  ))
                : null}
            </div>
          </>
        )}
  
        {oneProduct?.classifications.length >= 1 && (
          <>
            <div className="file-input-label-text font">
              {t("Available sizes")}
            </div>
            <div md="12" className="mt-2 d-flex flex-wrap">
              {oneProduct.colors
                ? oneProduct.classifications.map((el, index) => (
                    <div
                      key={index}
                      onClick={() => sizeClick(index, el)}
                      className=" ms-2 d-flex justify-content-center align-items-center file-input-label-text p-1 mb-1"
                      style={{
                        width: "60px",
                        border:
                          sizeIndex === index ? "3px solid black" : null,
                        borderRadius: "5px",
                        cursor: "pointer",
                        boxShadow: "rgb(151 151 151) 0px 4px 4px 0px",
                      }}
                    >
                      {el}
                    </div>
                  ))
                : null}
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button className="font" variant="secondary" onClick={handleClose}>
          {t("retreat")}
        </Button>
        <Button
          className="font"
          variant="success"
          onClick={() => addProductColorSizeToCart()}
        >
          {t("Confirmation")}
        </Button>
      </Modal.Footer>
    </Modal>
    
    }
    
    {
      settingsRes?.data?.showProductDescriptionTabs === true ?(
   
  
     
  <>
          <div>
  
            <Row className="mt-2">
            {
              settingsRes?.data?.showCategory === true?(
  
                <div className="cat-text">
                {oneProduct.category
                  ? Cookies.get("i18next") === "ar"
                    ? oneProduct.category.nameAr
                    : oneProduct.category.nameEn
                  : null}
              </div>
              ):null
            }
            <Row>
            <Col md="8">
              <div className="cat-title d-inline">
                {Cookies.get("i18next") === "ar" ? oneProduct.nameAr : oneProduct.nameEn}
                {showReviews ? (
                  <div className="cat-rate d-inline mx-3">
                    {oneProduct.ratingsAverage && oneProduct.ratingsAverage.toFixed(1)}
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </Col>
          </Row>
           
              <div className="font fw-bold m-1">
              {oneProduct.category
                ? Cookies.get("i18next") === "ar"
                  ? oneProduct.descriptionAr
                  : oneProduct.descriptionEn
                : null}
              </div>
  
            </Row>
           
            {
              settingsRes?.data?.showBrand === true?(
                <Row>
                <Col md="8" className="mt-4">
                  <div className="cat-text d-inline">{t("Brand")}</div>
                  <div className="barnd-text d-inline mx-1">
                    {oneProduct.brand
                      ? Cookies.get("i18next") === "ar"
                        ? oneProduct.brand.nameAr
                        : oneProduct.brand.nameEn
                      : null}
                  </div>
                </Col>
              </Row>
  
              ):null
            }
         
           
            {/*
  
   <Row>
              <Col md="8" className="mt-2 d-flex">
                {oneProduct.colors
                  ? oneProduct.colors.map((el, index) => (
                    <div
                      key={index}
                      onClick={() => colorClick(index, el)}
                      className="color ms-2"
                      style={{
                        backgroundColor: el,
                        border: indexColor === index ? "2px solid black" : null,
                       
                      }}
                    ></div>
                  ))
                  : null}
              </Col>
            </Row>
                      */}

                      <Row className="mt-2">
                      <div>{t("Specifications")}</div>
                      <div className="font fw-bold m-1"> {Cookies.get("i18next") === 'ar' ? oneProduct.richDescriptionAr : oneProduct.richDescriptionEn}</div>
                    </Row>
  
  
            {webSiteData.MULTI_VENDOR === "true" ? (
              <Row className="mt-4">
                <Col md="10">
                  <div className="cat-text"> {t("vendor name")}</div>
                  <Link to={`/${oneProduct.vendor ? oneProduct.vendor.username : ""}`}>
                    <div className="product-description d-inline">
                      {oneProduct.vendor
                        ? oneProduct.vendor.firstname + " " + oneProduct.vendor.lastname
                        : null}
                    </div>
                  </Link>
                </Col>
              </Row>
            ) : null}
  
            <Row className="mt-4">
              <Col md="12">
                <div className="product-price d-inline px-3 p-3 border">
                  {oneProduct.price1} <span className="p-1" >{currencytype}</span>
                </div>
                <div
                  onClick={handleAddToCart}
                  className="product-cart-add px-3 py-3 d-inline mx-3"
                  style={{ backgroundColor: settingsRes?.data?.primaryColor }}
                >
                  {t("Add to cart")}
                </div>
                {oneProduct.countInStock == 0 ? (
                  <div
                    className="my-4 p-2 "
                    style={{ color: "red", fontSize: "16px", fontWeight: "bold" }}
                  >
                    {t("product not found in stock")}
                  </div>
                ) : (
                  <div></div>
                )}
              </Col>
              <ToastContainer />
            </Row>
          </div>
        
        
          </>
      ):(
        <Tabs
        defaultActiveKey="home"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
  
        <Tab
          eventKey="home"
          title={t("Overview")}
          style={{ backgroundColor: "white" }}
        >
  
          <div>
  
            <Row className="mt-2">
            {
              settingsRes?.data?.showCategory === true?(
  
                <div className="cat-text">
                {oneProduct.category
                  ? Cookies.get("i18next") === "ar"
                    ? oneProduct.category.nameAr
                    : oneProduct.category.nameEn
                  : null}
              </div>
              ):null
            }
           
  
              <div className="font fw-bold m-1">
              {oneProduct.category
                ? Cookies.get("i18next") === "ar"
                  ? oneProduct.descriptionAr
                  : oneProduct.descriptionEn
                : null}
              </div>
  
            </Row>
            <Row>
              <Col md="8">
                <div className="cat-title d-inline">
                  {Cookies.get("i18next") === "ar" ? oneProduct.nameAr : oneProduct.nameEn}
                  {showReviews ? (
                    <div className="cat-rate d-inline mx-3">
                      {oneProduct.ratingsAverage && oneProduct.ratingsAverage.toFixed(1)}
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              </Col>
            </Row>
            {
              settingsRes?.data?.showBrand === true?(
                <Row>
                <Col md="8" className="mt-4">
                  <div className="cat-text d-inline">{t("Brand")}</div>
                  <div className="barnd-text d-inline mx-1">
                    {oneProduct.brand
                      ? Cookies.get("i18next") === "ar"
                        ? oneProduct.brand.nameAr
                        : oneProduct.brand.nameEn
                      : null}
                  </div>
                </Col>
              </Row>
  
              ):null
            }
         
           
            {/*
  
   <Row>
              <Col md="8" className="mt-2 d-flex">
                {oneProduct.colors
                  ? oneProduct.colors.map((el, index) => (
                    <div
                      key={index}
                      onClick={() => colorClick(index, el)}
                      className="color ms-2"
                      style={{
                        backgroundColor: el,
                        border: indexColor === index ? "2px solid black" : null,
                       
                      }}
                    ></div>
                  ))
                  : null}
              </Col>
            </Row>
                      */}
  
  
            {webSiteData.MULTI_VENDOR === "true" ? (
              <Row className="mt-4">
                <Col md="10">
                  <div className="cat-text"> {t("vendor name")}</div>
                  <Link to={`/${oneProduct.vendor ? oneProduct.vendor.username : ""}`}>
                    <div className="product-description d-inline">
                      {oneProduct.vendor
                        ? oneProduct.vendor.firstname + " " + oneProduct.vendor.lastname
                        : null}
                    </div>
                  </Link>
                </Col>
              </Row>
            ) : null}
  
            <Row className="mt-4">
              <Col md="12">
                <div className="product-price d-inline px-3 p-3 border">
                  {oneProduct.price1} <span className="p-1" >{currencytype}</span>
                </div>
                <div
                  onClick={handleAddToCart}
                  className="product-cart-add px-3 py-3 d-inline mx-3"
                  style={{ backgroundColor: settingsRes?.data?.primaryColor }}
                >
                  {t("Add to cart")}
                </div>
                {oneProduct.countInStock == 0 ? (
                  <div
                    className="my-4 p-2 "
                    style={{ color: "red", fontSize: "16px", fontWeight: "bold" }}
                  >
                    {t("product not found in stock")}
                  </div>
                ) : (
                  <div></div>
                )}
              </Col>
              <ToastContainer />
            </Row>
          </div>
        </Tab>
        <Tab
          eventKey="profile"
          title={t("Specifications")}
          style={{ backgroundColor: "white" }}
        >
          <Row className="mt-2">
            <div className="font fw-bold m-1"> {Cookies.get("i18next") == 'ar' ? oneProduct.richDescriptionAr : oneProduct.richDescriptionEn}</div>
          </Row>
        </Tab>
        
      </Tabs>
      )

    }



    </>
  );
};

export default ProductDescription;
