import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import notify from "../../hook/useNotification";
import { loginUser } from "../../redux/actions/authActions";
import getStoreNameFromUrl from "../../Components/Utilities/getStoreNameFromUrl";
import { useTranslation } from "react-i18next";
import {
  getOneStoreByName,
  getStoreById,
} from "../../redux/actions/storesActions";
const storeName = getStoreNameFromUrl();

const LoginHook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [val, setVal] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(true);
  const [isPress, setIsPress] = useState(false);



  const { t } = useTranslation();

  const onChangeVal = (e) => {
    setVal(e.target.value);
  };
  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const onSubmit = async () => {
    if (val === "") {
      notify("تاكد من الايميل او رقم الجوال", "error");
      return;
    }
    if (password === "") {
      notify("من فضلك ادخل الباسورد", "error");
      return;
    } else {
      setIsPress(true);
      setLoading(true);
      await dispatch(
        loginUser({
          val: val.toLocaleLowerCase(),
          password: password,
          storeId:storeNameResponse._id
        })
      );
      setLoading(false);
      setIsPress(false);
    }
  };
  const res = useSelector((state) => state.authReducers.loginUser);
  const storeNameResponse = useSelector((state) => state.allStores.oneStore);

  // get store if user logged successfully
  // useEffect(() => {
  //   if (loading === false) {
  //     if (res) {
  //       console.log("reseeeeeeeeeeeeeeeeeeeeee");
  //       console.log(res);
  //       dispatch(getStoreById(res.storeId));
  //     }
  //   }
  // }, [dispatch, loading, res]);

  // const storeById = useSelector((state) => state.allStores.oneStoreById);
  // console.log("storeByIddddddddddddddddddddddddddddddddd");
  // console.log("storeByIdddddddddd");
  // console.log(storeById);

  useEffect(() => {
    if (loading === false) {
      if (res) {
        if (res.isVerified === false) {
          navigate(`/verifyCode/${res._id}`);
        } else {
          if (res.token) {
            if (
              res.role !== "superAdmin" &&
              res.activeSubscription === "true"
            ) {
              if (
                new Date(res.lastSubscription?.endDateSubscription) < new Date()
              ) {
                if (res.role === "user" || res.role === "vendor") {
                  notify(
                    t("We are sorry, your store subscription has expired"),
                    "error"
                  );
                  return;
                } else {
                  localStorage.setItem("token", res.token);
                  localStorage.setItem("user", JSON.stringify(res));
                  notify(
                    t(
                      "You must renew the subscription to continue. Wait, you will be redirected to renew"
                    ),
                    "warn"
                  );
                  setTimeout(() => {
                    window.location.href = `/${storeNameResponse.name}/admin/subscriptions/add`;
                  }, 1500);
                }
              } else {
                localStorage.setItem("token", res.token);
                localStorage.setItem("user", JSON.stringify(res));
                notify("تم تسجيل الدخول بنجاح", "success");
                setTimeout(() => {
                  if (res.role === "admin" || res.role === "superAdmin") {
                    //console.log(res);
                    if (res.role === "superAdmin") {
                      console.log("superrrrrrrrrrrrrrrrrrrrrrrrrrrrr");
                      window.location.href = `/Super/admin/products`;
                    } else {
                      console.log(
                        "adinnnnnnnnnnnnnnnnnnnnnnnnnnnnnssssssssssssssssssssssss"
                      );

                      window.location.href = `/${storeNameResponse.name}/admin/products`;
                    }
                  } else {
                    console.log("othqqqqqqqqqqqqqqqqqqqqqqqq");
                    window.location.href = `/${storeNameResponse.name}`;
                  }
                }, 1500);
              }
            } else {
              console.log("dddddddddddddddddddddddddddddddd");
              localStorage.setItem("token", res.token);
              localStorage.setItem("user", JSON.stringify(res));
              notify("تم تسجيل الدخول بنجاح", "success");
              setTimeout(() => {
                if (res.role === "admin" || res.role === "superAdmin") {
                  //console.log(res);
                  if (res.role === "superAdmin")
                    window.location.href = `/Super/admin/products`;
                  else
                    window.location.href = `/${storeNameResponse.name}/admin/products`;
                } else {
                  window.location.href = `/${storeNameResponse.name}`;
                }
              }, 1500);
            }
          } else {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            localStorage.removeItem("page");
          }
        }

        if (res.data) {
          if (
            res.data === "password is wrong" ||
            res.data === "The user not found"
          ) {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            notify("خطأ في البريد الالكتروني أو كلمة المرور   ", "error");
          }
          if (res.data === "The user is blocked") {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            notify("هذا المستخدم محظور من قبل الادارة", "error");
          }
        }

        setLoading(true);
      }
    }
  }, [loading]);

  return [
    val,
    password,
    onChangeVal,
    onChangePassword,
    loading,
    onSubmit,
    isPress,
  ];
};

export default LoginHook;
