import React from "react";
import BrandCard from "./BrandCard";
import { Container, Row, Spinner } from "react-bootstrap";
import { t } from "i18next";
import Cookies from "js-cookie";
import BrandPageHook from "../../hook/brand/BrandPageHook";
const BrandContainer = ({ data, loading }) => {
  return (
    <Container>
      <div className="admin-content-text mt-2 ">{t("All brands")} </div>
      <Row className="my-2 d-flex justify-content-center">
        {/* <BrandCard img={brand1} /> */}

        {(
          data ? (
            data.map((el) => {
              return <BrandCard id={el._id} key={el._id} img={el.logo} />;
            })
          ) : (
            <h3 className="text-center">{t("no brands")} </h3>
          )
        // ) : (
        //   <Spinner animation="border" variant="primary" />
        )}
      </Row>
    </Container>
  );
};

export default BrandContainer;
