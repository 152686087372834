import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createOrders } from "../../redux/actions/checkoutAction";
import {
  getPaymentMethods,
  payWithPayMob,
} from "../../redux/actions/paymentMethodAction";
import {
  addTransaction,
  userTransaction,
} from "../../redux/actions/walletAction";
import UserCartHook from "../cart/UserCartHook";
import CryptoJS from "crypto-js";
import notify from "../useNotification";
import axios from "axios";
import config from "../../Urway/Configuration.json";
import { getAllCartItems } from "../../redux/actions/cartAction";
import getStoreNameFromUrl from "../../Components/Utilities/getStoreNameFromUrl";
const generateHashSHA256 = (hashSequence) => {
  // hashSequence = trackid | terminalId | password | secret | amount | currency
  let hash = CryptoJS.SHA256(hashSequence).toString();
  return hash;
};

const storeName=getStoreNameFromUrl();

const OrderPayCashHook = (shippingPrice) => {
  const [addressId, setAddressId] = useState();
  const [paymentId, setPaymentId] = useState();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [loadingPaymob, setLoadingPaymob] = useState(true);
  const [loadingVodafoneCash, setVodafoneCash] = useState(true);
  const [vodafoneCashUrl, setVodafoneCashUrl] = useState("");
  const [loadingTransaction, setLoadingTransaction] = useState(true);
  const [userCredit, setuserCredit] = useState();
  const [orderToken, setOrderToken] = useState("");
  const dispatch = useDispatch();
  const [
    itemNumber,
    cartItems,
    totalPrice,
    couponNameAfterApplay,
    totalPriceAfterDiscount,
    vendorId,
    couponId,
  ] = UserCartHook();
  const [double, setDouble] = useState(false);

  let resParameter = {};
  let index = 0;
  let count = 0;
  let queryParam = "";

  var user = "";

  if (localStorage.getItem("user") != null) {
    user = JSON.parse(localStorage.getItem("user"));
  }

  const handleChooseAddress = (e) => {
    setAddressId(e.target.value);
  };

  const handlePaymentMethods = (e) => {
    setPaymentId(e.target.value);
  };

  const storeNameResponse = useSelector((state) => state.allStores.oneStore);

  useEffect(() => {
    const get = async () => {
      await dispatch(getPaymentMethods(storeNameResponse?._id));

      setLoadingTransaction(true);
      await dispatch(userTransaction(user?._id));
      setLoadingTransaction(false);
    };
    get();
  }, []);

  const payments = useSelector(
    (state) => state.paymentMethodReducer.paymentMethods
  );

  const totalPriceData =
    totalPriceAfterDiscount > 0 ? totalPriceAfterDiscount : totalPrice;

  const createOrderCash = async () => {
    if (cartItems.length <= 0) {
      notify("لا يوجد منتجات فالعربة", "warn");
      return;
    }
    if (!addressId) {
      notify("من فضلك اختر عنوان", "warn");
      return;
    }
    if (!paymentId) {
      notify("من فضلك اختر طريقه شحن", "warn");
      return;
    }

    const findPaymentObj = payments.find((el) => el._id === paymentId);

    //console.log(findPaymentObj);

    if (findPaymentObj.key === 3) {
      const values = {
        action: "1",
        address: "",
        amount:
          parseInt(totalPriceAfterDiscount) > 0
            ? parseInt(totalPriceAfterDiscount)
            : parseInt(totalPrice) + parseInt(shippingPrice),
        cardToken: "",
        city: "",
        country: "SA",
        currency: "SAR",
        customerEmail: user.email,
        firstName: user.firstName,
        lastName: user.firstName,
        merchantIp: "46.101.29.200",
        password: config.password,
        phoneNumber: user.mobile,
        state: "",
        terminalId: config.terminalId,
        tokenOperation: "",
        tokenizationType: "0",
        trackid: Math.floor(Math.random() * 100000) + 1,
        transid: "",
        udf1: `${paymentId},${addressId},${shippingPrice}`,
        udf2: "https://abayatlyan.com/order/paymentStatus",
        udf3: "en",
        udf4: "",
        udf5: "",
        zipCode: "",
      };
      let hashSequence = generateHashSHA256(
        values.trackid +
          "|" +
          values.terminalId +
          "|" +
          config.password +
          "|" +
          config.merchantkey +
          "|" +
          values.amount +
          "|" +
          values.currency
      );
      values.requestHash = hashSequence;

      axios
        .post(config.service_url, values)
        .then((res) => {
          //console.log("targetUrl "+res.data.targetUrl)
          resParameter = res.data;
          //console.log("resParameter ",resParameter)
          if (resParameter.targetUrl + "" === "null") {
            for (let [key, value] of Object.entries(resParameter)) {
              index = ++index;
            }
            for (let [key, value] of Object.entries(resParameter)) {
              count = ++count;
              queryParam = queryParam + key + "=" + value;
              if (count < index) queryParam = queryParam + "&";
            }
            //console.log("index : ",index,queryParam)
            window.location.assign(
              window.location.origin.toString() + "/paymentStatus?" + queryParam
            );
            setDouble(true);
          } else {
            window.location.assign(
              res.data.targetUrl.replace("?", "") +
                "?paymentid=" +
                res.data.payid
            );
            setDouble(true);
          }
        })
        .catch((error) => {});
    } else if (findPaymentObj.key === 5) {
      //paymob

      let key = 5;
      let user = JSON.parse(localStorage.getItem("user"));
      let user_id = user._id;
      let totalPrice = 0;
      var len = cartItems.length;

      for (var i = 0; i < len; i++) {
        totalPrice += cartItems[i].productPrice;
      }

      //create order
      let orderObject = {
        orderItems: cartItems.map((el) => {
          return {
            quantity: el.quantity,
            classification: el.classification,
            product: el.product._id,
            price: el.product.price1,
            color: el.color,
            productNotes: el.productNotes,
          };
        }),
        vendor: cartItems[0].product.vendor,
        user: user._id,
        userAddress: addressId,
        paymentMethodId: paymentId,
        coupon: couponId,
        totalPrice: totalPriceData + shippingPrice,
        storeId: user?.storeId,
        isPaid: false,
      };
      if (couponId === "") delete orderObject.coupon;

      setLoadingPaymob(true);
      await dispatch(
        payWithPayMob({
          key: key,
          user_id: user_id,
          amount_cents: totalPrice,
          _id: findPaymentObj._id,
        })
      );

      await dispatch(createOrders(orderObject));
      setLoadingPaymob(false);
    } else if (findPaymentObj.key === 6) {
      let key = 6;
      let user = JSON.parse(localStorage.getItem("user"));
      let user_id = user._id;
      let totalPrice = 0;
      var len = cartItems.length;

      for (var i = 0; i < len; i++) {
        totalPrice += cartItems[i].productPrice;
      }

      //create order
      let orderObject = {
        orderItems: cartItems.map((el) => {
          return {
            quantity: el.quantity,
            classification: el.classification,
            productNotes: el.productNotes,
            product: el.product._id,
            price: el.product.price1,
            color: el.color,
          };
        }),
        vendor: cartItems[0].product.vendor,
        user: user._id,
        userAddress: addressId,
        paymentMethodId: paymentId,
        coupon: couponId,
        totalPrice: totalPriceData + shippingPrice,
        isPaid: false,
        storeId: user?.storeId,
      };
      if (couponId === "") delete orderObject.coupon;

      setLoadingPaymob(true);
      await dispatch(
        payWithPayMob({
          key: key,
          user_id: user_id,
          amount_cents: totalPrice,
          _id: findPaymentObj._id,
          mobile_number: "01010101010",
        })
      );
      setLoadingPaymob(false);
    } else if (findPaymentObj.key === 7) {
      //valu
      let key = 7;
      let user = JSON.parse(localStorage.getItem("user"));
      let user_id = user._id;
      let totalPrice = 0;
      var len = cartItems.length;

      for (var i = 0; i < len; i++) {
        totalPrice += cartItems[i].productPrice;
      }

      //create order
      let orderObject = {
        orderItems: cartItems.map((el) => {
          return {
            quantity: el.quantity,
            classification: el.classification,
            productNotes: el.productNotes,
            product: el.product._id,
            price: el.product.price1,
            color: el.color,
          };
        }),
        vendor: cartItems[0].product.vendor,
        user: user._id,
        userAddress: addressId,
        paymentMethodId: paymentId,
        coupon: couponId,
        totalPrice: totalPriceData + shippingPrice,
        isPaid: false,
        storeId: user?.storeId,
      };
      if (couponId === "") delete orderObject.coupon;

      setLoadingPaymob(true);
      await dispatch(
        payWithPayMob({
          key: key,
          user_id: user_id,
          amount_cents: totalPrice,
          _id: findPaymentObj._id,
        })
      );
      setLoadingPaymob(false);
    } else {
      if (findPaymentObj.key === 4) {
        // console.log("___", totalPriceData)
        // console.log("___", shippingPrice)

        if (totalPriceData + shippingPrice > userCredit) {
          return notify("لا يوجد لديك رصيد كافي", "warn");
        } else {
          await dispatch(
            addTransaction({
              price: -(totalPriceData + shippingPrice),
              type: "2",
              user: user._id,
              addedBy: user._id,
            })
          );
        }
      }

      setLoading(true);
      // await
      let orderObject = {
        orderItems: cartItems.map((el) => {
          return {
            quantity: el.quantity,
            classification: el.classification,
            productNotes: el.productNotes,
            product: el.product._id,
            price: el.product.price1,
            color: el.color,
          };
        }),
        vendor: cartItems[0].product.vendor,
        user: user._id,
        userAddress: addressId,
        paymentMethodId: paymentId,
        coupon: couponId,
        totalPrice: totalPriceData + shippingPrice,
        isPaid: findPaymentObj.key === 4 ? true : false,
        storeId: user?.storeId,
      };
      // console.log(couponNameAfterApplay)
      if (couponId === "") delete orderObject.coupon;

      await dispatch(createOrders(orderObject));
      setLoading(false);

      //redirect here
    }
  };

  const res = useSelector((state) => state.checkoutReducer.createOrder);

  const paymobResponse = useSelector(
    (state) => state.paymentMethodReducer.payMobMethod
  );

  useEffect(() => {
    // window.location.replace(paymobResponse);
    if (loadingPaymob === false) {
      localStorage.setItem("order_id", res?.data?._id);
      if (paymobResponse) {
        //console.log(res.data._id);

        window.location.replace(paymobResponse);
      }

      //  window.location.replace(paymobResponse);
    }
  }, [loadingPaymob]);

  useEffect(() => {
    if (loadingVodafoneCash === false) {
      localStorage.setItem("order_id", res?.data?._id);
      //console.log(res.data._id);
      window.location.replace(vodafoneCashUrl);
    }
  }, [vodafoneCashUrl]);

  const userTransactions = useSelector(
    (state) => state.walletReducer.allUserTransaction
  );
  useEffect(() => {
    if (loadingTransaction === false) {
      if (userTransactions) {
        setuserCredit(userTransactions.userCredit);
        // if (totalPriceData + shippingPrice < userTransactions.userCredit) {
        //   return notify("لا يوجد لديك رصيد كافي", "warn");
        // }
      }
    }
  }, [loadingTransaction]);

  useEffect(() => {
    if (loading === false) {
      if (res && res.success === true) {
        notify("تم انشاء الطلب بنجاح", "success");
        dispatch(getAllCartItems(user._id));

        setTimeout(() => {
          navigate(`/user/allorders`);
        }, 1500);
      } else {
        notify("فشل فيه اكمال الطلب", "warn");
      }
    }
  }, [loading]);

  return [
    handleChooseAddress,
    createOrderCash,
    payments,
    totalPriceData,
    handlePaymentMethods,
  ];
};

export default OrderPayCashHook;
