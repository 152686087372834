import React from 'react';
import SideBar from '../../Components/Admin/SideBar';
import AdminAllStores from '../../Components/Admin/AdminAllStores';


function AdminAllStoresPage() {
  return (
    <div className="d-flex justify-content-start" >
    <div>
    <SideBar />
    </div>
    <div className='p-4' style={{width:"100%"}} >
        <AdminAllStores />
    </div>
    </div>
  )
}

export default AdminAllStoresPage