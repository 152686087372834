import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSettings } from "../../redux/actions/settingsAcions";
import getStoreNameFromUrl from "../../Components/Utilities/getStoreNameFromUrl";
import { getOneStoreByName } from "../../redux/actions/storesActions";

const ProtectedRouteHook = () => {
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem("user"))
  );
  // const [isUser, setIsUser] = useState(false);
  // const [isAdmin, setIsAdmin] = useState(false);
  // const [isVendor, setIsVendor] = useState(false);
  let isUser=false;
  let isAdmin=false;
  let isVendor=false;



 // const dispatch = useDispatch();
  const storeName = getStoreNameFromUrl();
  // useEffect(() => {
    if (userData != null &&(userData?.storeObj?.name==storeName) ) {
      if (userData.role === "user") {
        isUser=true;
        // setIsUser(true);
        // setIsAdmin(false);
        // setIsVendor(false);
      } else if (userData.role === "admin") {
        isAdmin=true;
        // setIsUser(false);
        // setIsVendor(false);
        // setIsAdmin(true);
      } else if (userData.role === "vendor") {
        isVendor=true;
        // setIsVendor(true);
        // setIsUser(false);
        // setIsAdmin(false);
      }
    }
    else if(userData != null && userData?.role === "superAdmin"){
        console.log("superAdmin");
        isAdmin=true;
        // setIsUser(false);
        // setIsVendor(false);
        // setIsAdmin(true);
    }
    //  else {
    //   setIsAdmin(false);
    //   setIsUser(false);
    //   setIsVendor(false);
    //   // localStorage.removeItem('user');
    //   // localStorage.removeItem('token');
    // }
  // }, []);
  // const storeName = getStoreNameFromUrl();
  // useEffect(() => {
  //   storeName!='' && dispatch(getOneStoreByName(storeName));
  // }, []);
  // const storeNameResponse = useSelector((state) => state.allStores.oneStore);
  // useEffect(() => {
  //   if (storeNameResponse) {
  //     console.log("storeNameResponse");
  //     console.log(storeNameResponse);
  //     dispatch(getSettings(storeNameResponse._id));
  //   }
  // }, [dispatch, storeNameResponse]);

  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  // useEffect(() => {
  //   if (settingsRes && settingsRes.data) {
  //     // console.log(document.getElementsByTagName("head")[0].children.length);
  //     // var meta = document.createElement("meta");
  //     // meta.name = "description";
  //     // meta.content = settingsRes.data?.descriptionSite;

  //     // var metaKywords = document.createElement("meta");
  //     // metaKywords.name = "keywords";
  //     // metaKywords.content = settingsRes.data?.keywordsSite;

  //     if (settingsRes?.data?.blockSite !== "") {
  //       let blockStr = settingsRes?.data?.blockSite;
  //       let blockArr = blockStr.trim().split("</script>");
  //       // console.log(blockStr.split('</script>')[2]+'</script>')

  //       for (let i = 0; i < blockArr.length; i++) {
  //         if (blockArr[i] != "") {
  //           var doc = new DOMParser().parseFromString(
  //             blockArr[i] + "</script>",
  //             "text/xml"
  //           );
  //           document
  //             .getElementsByTagName("head")[0]
  //             .appendChild(doc.documentElement);
  //         }
  //       }

  //       // var doc = new DOMParser().parseFromString(blockArr[2]+'</script>', "text/xml");
  //       // document.getElementsByTagName("head")[0].appendChild(doc.documentElement);
  //       // console.log('blockSite')
  //       // console.log(doc.documentElement)
  //       // console.log('blockSite')
  //     }

  //     // document
  //     //   .getElementsByTagName("head")[0]
  //     //   .insertBefore(
  //     //     meta,
  //     //     document.getElementsByTagName("head")[0].children[0]
  //     //   );

  //     //   document
  //     //   .getElementsByTagName("head")[0]
  //     //   .insertBefore(
  //     //     metaKywords,
  //     //     document.getElementsByTagName("head")[0].children[1]
  //     //   );

  //     // console.log("ssssspppppssssss");
  //     // console.log(
  //     //   document.getElementsByTagName("head")[0].children.description
  //     // );
  //     // console.log(document.getElementsByTagName("head")[0].length);
  //     // console.log("sssssspppsssssssssss");
  //   }
  // }, []);

  return [isUser, isAdmin, isVendor, userData, settingsRes];
};

export default ProtectedRouteHook;
