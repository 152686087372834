import React from "react";
import { Button, Image, Modal } from "react-bootstrap";
import AddCatHook from "../../hook/category/AddCatHook";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import DataTable from "react-data-table-component";
import { useNavigate, useParams } from "react-router-dom";
import add from "../../Images/add.png";
import { ToastContainer } from "react-toastify";
import AddSubCatHook from "../../hook/category/AddSubCatHook";
import AddButton from "../Utilities/AddButton";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { BiCopy } from "react-icons/bi";

const AdminSubCategories = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const [
    img,
    nameAr,
    nameEn,
    isPress,
    loading,
    handleSubmit,
    onImageChange,
    onChangeNameAr,
    onChangeNameEn,
    categories,
    show,
    handleShow,
    handleClose,
    handleDelete,
    clickHandler,
    handleShowFun,
    settingsRes,
  ] = AddSubCatHook(id);

  const navigate = useNavigate();

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  let newCategories = categories.map((category) => {
    return {
      ...category,
      updatedAt: formatDate(category.updatedAt),
      status: category.status.toString(),
    };
  });

  let columns = [
    {
      name: t("image"),
      cell: (row) => (
        <img
          src={row.image}
          width="60px"
          alt="imgg"
          height="60px"
          style={{ padding: "3px", marginLeft: "5px", objectFit: "contain" }}
        />
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: t("subcategory name in arabic"),
      selector: "nameAr",
      sortable: true,
    },

    {
      name: t("subcategory name in english"),
      selector: "nameEn",
      sortable: true,
    },
    // {
    //   name: "حالة التصنيف ",
    //   selector: "status",
    //   sortable: true,
    // },
    {
      name: t("category status"),
      selector: (row) => (row.status === "true" ? "نشط" : "غير نشط"),
      sortable: true,
    },

    {
      name: t("Date created"),
      selector: "updatedAt",
      sortable: true,
    },
    // {
    //   name: t("  نسخ التصنيف الفرعي"),
    //   cell: (row) => (
    //     <Button onClick={() => alert(row._id)}>
    //       <BiCopy />
    //     </Button>
    //   ),
    //   ignoreRowClick: true,
    //   allowOverflow: true,
    // },
    {
      name: t("Modification"),
      cell: (row) => (
        <Button
          variant="secondary"
          onClick={() => clickHandler(row._id)}
          id={row._id}
        >
          تعديل
          <i className="fa fa-pencil-square-o mx-1" />
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: t("the status"),
      cell: (row) =>
        row.status == "true" ? (
          <Button
            variant="danger"
            onClick={() => {
              handleShowFun(row._id, false);
            }}
            id={row._id}
          >
            حظر
            <i className="fa fa-lock mx-1" />
          </Button>
        ) : (
          <Button
            variant="success"
            onClick={() => {
              handleShowFun(row._id, true);
            }}
            id={row._id}
          >
            تفعيل
            <i className="fa fa-unlock mx-1" />
          </Button>
        ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const tableData = {
    columns,
    data: newCategories,
  };

  return (
    <div>
      <Helmet>
        <title>
          {`  ${t("Subcategories")}  -${
            Cookies.get("i18next") === "ar"
              ? settingsRes.data?.titleAr
              : settingsRes.data?.titleEn
          }`}{" "}
        </title>
      </Helmet>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            <div className="font">{t("Confirm deletion")}</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="font">{t("are sure of the deleting process")}</div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="font" variant="secondary" onClick={handleClose}>
            {t("retreat")}
          </Button>
          <Button className="font" variant="danger" onClick={handleDelete}>
            {t("delete")}
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="d-flex justify-content-between">
        <div className="admin-content-text">
          {t("Classification Management")}
        </div>
        <AddButton
          title={t("add Subcategories")}
          navigateTo={`/admin/addsubcategory/${id}`}
        />
      </div>

      <DataTableExtensions {...tableData} filterPlaceholder={t("search here")}>
        <DataTable
          noHeader
          defaultSortAsc={false}
          pagination
          highlightOnHover
        />
      </DataTableExtensions>
      <ToastContainer />
    </div>
  );
};

export default AdminSubCategories;

// <div>
// <Button
//   style={{ backgroundColor: "darkgreen" }}
//   variant="secondary"
//   onClick={() => navigate(`/admin/addsubcategory/${id}`)}
// >
//   إضافة تصنيف الفرعي
//   <img
//     src={add}
//     alt=""
//     width="30px"
//     height="35px"
//     style={{ cursor: "pointer", marginRight: "8px" }}
//     onClick={() => navigate(`/admin/addsubcategory/${id}`)}
//   />
// </Button>
// </div>
