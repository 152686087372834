import React from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet";
import ResgisterStoreHook from "../../hook/auth/ResgisterStoreHook";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import { Col, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";

function AdminAddStore() {
  const { t } = useTranslation();
  const [
    firstname,
    lastname,
    mobile,
    email,
    password,
    confirmPassword,
    loading,
    onChangeFirstname,
    onChangeLastname,
    onChangeMobile,
    onChangeEmail,
    onChangePassword,
    onChangeConfirmPassword,
    onSubmit,
    storeName,
    storeNameAr,
    onChangeStoreName,
    onChangeStoreNameAr,
    onChangeMultiVendor
  ] = ResgisterStoreHook();

  const location=useLocation();
  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  return (
    <div>
      <Helmet>
        <title>
          {`${!location.state ? 'اضافة متجر' : 'تعديل متجر'} - ${
            Cookies.get("i18next") === "ar"
              ? settingsRes.data?.titleAr
              : settingsRes.data?.titleEn
          }`}{" "}
        </title>
      </Helmet>

      <Row className="justify-content-start">
        <div className="admin-content-text pb-4">
          {!location.state ? 'اضافة متجر' : 'تعديل متجر'}
          </div>
        <Col sm="8">
        <label htmlFor="storeName" className="d-block mt-2 ">
            {t("SpecialStoreName")}{" "}
          </label>
          <input
            id="storeName"
            value={storeName}
            onChange={onChangeStoreName}
            type="text"
            className="input-form d-block mt-2 px-3"
          />

          <label htmlFor="storeNameAr" className="d-block mt-2 ">
            {t("Store Name in Arabic")}{" "}
          </label>
          <input
            id="storeNameAr"
            value={storeNameAr}
            onChange={onChangeStoreNameAr}
            type="text"
            className="input-form d-block mt-2 px-3"
          />

          {
            !location.state &&(
              <>
                <label htmlFor="first name" className="d-block mt-2 ">
            {t("first name")}{" "}
          </label>
          <input
            id="first name"
            value={firstname}
            onChange={onChangeFirstname}
            type="text"
            className="input-form d-block mt-2 px-3"
          />

          <label htmlFor="last name" className="d-block mt-2 ">
            {t("last name")}{" "}
          </label>
          <input
          id="last name"
            value={lastname}
            onChange={onChangeLastname}
            type="text"
            className="input-form d-block mt-2 px-3"
          />

          <label htmlFor="email" className="d-block mt-2 ">
            {t("email")}{" "}
          </label>
          <input
            id="email"
            value={email}
            onChange={onChangeEmail}
            type="email"
            className="input-form d-block mt-2 px-3"
          />

          <label htmlFor="mobile" className="d-block mt-2 ">
            {t("mobile")}{" "}
          </label>
          <input
            id="mobile"
            value={mobile}
            onChange={onChangeMobile}
            type="phone"
            className="input-form d-block mt-2 px-3"
          />

          <label htmlFor="multivendor" className="d-block mt-2 ">
            {t("multivendor")}{" "}
          </label>
          <select 
          id="multivendor"
          className="input-form d-block mt-2 px-3"
          onChange={onChangeMultiVendor}
          >
            <option value={true}>{t("yes")}</option>
            <option value={false}>{t("no")}</option>
          </select>


          <label htmlFor="password" className="d-block mt-2 ">
            {t("password")}{" "}
          </label>
          <input
            id="password"
            value={password}
            onChange={onChangePassword}
            type="password"
            className="input-form d-block mt-2 px-3"
          />

          <label htmlFor="confirm password" className="d-block mt-2 ">
            {t("confirm password")}{" "}
          </label>
          <input
            id="confirm password"
            value={confirmPassword}
            onChange={onChangeConfirmPassword}
            type="password"
            className="input-form d-block mt-2 px-3"
          />
              </>
            )
          }
        
        </Col>
      </Row>

      <Row>
        <Col sm="8" className="d-flex justify-content-end ">
          <button
            onClick={onSubmit}
            className="btn-save d-inline mt-2 "
            style={{ backgroundColor: settingsRes?.data?.primaryColor }}
          >
            {t("save")}
          </button>
        </Col>
      </Row>

      <ToastContainer />
    </div>
  );
}

export default AdminAddStore;
