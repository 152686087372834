import React from "react";
import SideBar from "../../Components/Admin/SideBar";
import AdminSubscription from "../../Components/Admin/AdminSubscription";

const AdminSupscriptionPage = () => {
  return (
    <div className="d-flex justify-content-start">
      <div>
        <SideBar />
      </div>
      <div className="p-4" style={{ width: "100%" }}>
        <AdminSubscription />
      </div>
    </div>
  );
};
export default AdminSupscriptionPage;
