import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import notify from "../../hook/useNotification";
import {
  editUserAddress,
  getOneUserAddress,
} from "../../redux/actions/userAddressActions";
import getStoreNameFromUrl from "../../Components/Utilities/getStoreNameFromUrl";

const storeName=getStoreNameFromUrl();

const EditAddressHook = (id) => {
  const [name, setName] = useState("");
  const [zone, setZone] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");

  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");

  const [mobile, setMobile] = useState("");
  const [closeSign, setCloseSign] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onChangeName = (e) => {
    e.persist();
    setName(e.target.value);
  };
  const onChangeZone = (e) => {
    e.persist();
    setZone(e.target.value);
  };
  const onChangeCity = (e) => {
    e.persist();
    setCity(e.target.value);
  };
  const onChangeAddress = (e) => {
    e.persist();
    setAddress(e.target.value);
  };
  const onChangeMobile = (e) => {
    e.persist();
    setMobile(e.target.value);
  };
  const onChangeCloseSign = (e) => {
    e.persist();
    setCloseSign(e.target.value);
  };
  const onChangeLatLang = (lat, lang) => {

    setLatitude(lat);
    setLongitude(lang);
  }

  const oneUserAddress = useSelector(
    (state) => state.userAddressReducer.oneUserAddAddress
  );

  useEffect(() => {
    const get = async () => {
      setLoadingData(true);
      await dispatch(getOneUserAddress(id));
      setLoadingData(false);
    };
    get();
  }, []);

  useEffect(() => {
    if (oneUserAddress) {
      setName(oneUserAddress.name);
      setZone(oneUserAddress.zone);
      setCity(oneUserAddress.city);
      setAddress(oneUserAddress.address);
      setMobile(oneUserAddress.mobile);
      setCloseSign(oneUserAddress.closeSign);
      setLatitude(oneUserAddress.latitude);
      setLongitude(oneUserAddress.longitude);
    }
  }, [loadingData]);

  var user = "";
  if (localStorage.getItem("user") != null) {
    user = JSON.parse(localStorage.getItem("user"));
  }
  const res = useSelector(
    (state) => state.userAddressReducer.editUserAddAddress
  );

  const onSubmit = async () => {
    if (
      name === "" ||
      zone === "" ||
      city === "" ||
      closeSign === "" ||
      mobile === "" ||
      address === ""
    ) {
      notify("من فضلك اكمل البيانات", "error");
    }
    setLoading(true);
    await dispatch(
      editUserAddress(id, {
        name: name,
        city: city,
        zone: zone,
        closeSign: closeSign,
        address: address,
        mobile: mobile,
        latitude: latitude,
        longitude: longitude,
      })
    );
    setLoading(false);
  };

  useEffect(() => {
    if (loading === false) {
      if (res && res.success === true) {
        notify("تم التعديل بنجاح", "success");
        setTimeout(() => {
          navigate(`/user/addresses`);
        }, 1000);
      } else {
        notify("فشل في عملية التعديل", "error");
      }
    }
  }, [loading]);

  return [
    name,
    zone,
    city,
    address,
    mobile,
    closeSign,
    onChangeName,
    onChangeZone,
    onChangeCity,
    onChangeAddress,
    onChangeMobile,
    onChangeCloseSign,
    onSubmit,
    latitude,
    longitude,
    onChangeLatLang
  ];
};

export default EditAddressHook;
