import {
  CREATE_NEW_ADS,
  GET_ALL_ADS,
  DELETE_ADS,
  UPDATE_ADS,
  GET_ONE_ADS,
  GET_ALL_ADS_WEB_SITE,
} from "../type";
import { useInsertDataWithImage } from "../../hooks/useInsertData";
import { useGetData } from "../../hooks/useGetData";
import useDeleteData from "../../hooks/useDeleteData";
import { useUpdateDataWithImage } from "../../hooks/useUpdateData";

export const createNewAds = (formData) => async (dispatch) => {
  try {
    const response = await useInsertDataWithImage(`/api/v1/ads`, formData);

    dispatch({
      type: CREATE_NEW_ADS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: CREATE_NEW_ADS,
      payload: error,
    });
  }
};

export const getAllAds = (storeId) => async (dispatch) => {
  try {
    const response = await useGetData(`/api/v1/ads?storeId=${storeId}`);

    dispatch({
      type: GET_ALL_ADS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_ADS,
      payload: error,
    });
  }
};
export const getAllAdsForWebsite = (storeId) => async (dispatch) => {
  try {
    const response = await useGetData(`/api/v1/ads/website?storeId=${storeId}`);

    dispatch({
      type: GET_ALL_ADS_WEB_SITE,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_ADS_WEB_SITE,
      payload: error,
    });
  }
};
export const deleteAds = (id) => async (dispatch) => {
  try {
    const response = await useDeleteData(`/api/v1/ads/${id}`);

    dispatch({
      type: DELETE_ADS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: DELETE_ADS,
      payload: error,
    });
  }
};

export const updateAds = (id, formData) => async (dispatch) => {
  try {
    const response = await useUpdateDataWithImage(
      `/api/v1/ads/${id}`,
      formData
    );

    dispatch({
      type: UPDATE_ADS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_ADS,
      payload: error,
    });
  }
};

export const getOneAds = (id) => async (dispatch) => {
  try {
    const response = await useGetData(`/api/v1/ads/${id}`);

    dispatch({
      type: GET_ONE_ADS,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_ONE_ADS,
      payload: error,
    });
  }
};
