import { GET_SETTINGS, EDIT_SETTINGS, GET_WEBSITE_DATA } from "../type";
const inital = {
  settings: [],
  editSettings: [],
  webSiteData: []
};

const settingsReducers = (state = inital, action) => {
  switch (action.type) {
    case GET_SETTINGS:
      return {
        ...state,
        settings: action.payload,
      };
    case EDIT_SETTINGS:
      return {
        ...state,
        editSettings: action.payload,
      };
    case GET_WEBSITE_DATA:
      return {
        ...state,
        webSiteData: action.payload,
      };
    default:
      return state;
  }
};
export default settingsReducers;
