import React, { useEffect, useState } from "react";
import { Button, Container, Modal, Row, Spinner } from "react-bootstrap";
import DataTable from "react-data-table-component";
import ViewAllUsersHook from "../../hook/admin/ViewAllUsersHook";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import AdminEditUserHook from "../../hook/admin/AdminEditUserHook";
import { updateUser } from "../../redux/actions/usersActions";
import { useDispatch, useSelector } from "react-redux";
import notify from "../../hook/useNotification";
import { ToastContainer } from "react-toastify";
import add from "../../Images/add.png";
import { useNavigate } from "react-router-dom";
import {
  getAllSubscription,
  updateSlider,
  updateSubscription,
} from "../../redux/actions/subscriptionAction";
import AddButton from "../Utilities/AddButton";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import Cookies from "js-cookie";
import getStoreNameFromUrl from "../Utilities/getStoreNameFromUrl";
import SuperAdminSelectStoreComponent from "../Utilities/SuperAdminSelectStoreComponent";
import AdminSubscriptionHook from "../../hook/admin/AdminSubscriptionHook";
import formatDate2 from "../Utilities/formDate2";

const storeName = getStoreNameFromUrl();

const AdminSubscription = () => {
  const { t } = useTranslation();

  const [
    allSubscription,
    clickHandler,
    settingsRes,
    showSelectStoreComponent,
    showAddComponnet,
    storeIdRes,
    deleteModal,
    closeDeleteModal,
    showDeleteModal,
    deleteSubscription,
  ] = AdminSubscriptionHook();

  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem("user"));

  const handleShowFun = async (id, status) => {
    setLoading(true);
    await dispatch(
      updateSubscription(id, {
        status: status,
      })
    );
    setLoading(false);
  };

  const res = useSelector((state) => state.sliderReducer.updateSlider);

  useEffect(() => {
    if (loading === false) {
      if (res) {
        notify(t("Modified successfully"), "success");
        setTimeout(async () => {
          if (storeIdRes !== "") {
            await dispatch(getAllSubscription(storeIdRes));
          } else {
            await dispatch(getAllSubscription(user?.storeId));
          }
        }, 1500);
      } else {
        notify(t("Failed in the editing process"), "error");
      }
    }
  }, [loading]);

  let columns = [
    {
      name: t("Store"),
      selector: (row) => row.store?.name,
    },
    {
      name: t("Added by"),
      selector: (row) => row.user?.firstname + " " + row.user?.lastname,
    },
    {
      name: t("Monthly Subscription Period"),
      selector: (row) => (row.period >= 1 ? row.period + " أشهر" : 15 + " يوم"),
    },
    {
      name: t("Price"),
      selector: "priceSubscription",
    },
    {
      name: t("starting date"),
      selector: (row) => formatDate2(row.createdAt),
    },
    {
      name: t("experation date"),
      selector: (row) => formatDate2(row.endDateSubscription),
    },
  ];

  if (showSelectStoreComponent) {
    columns.push(
      {
        name: t("delete"),
        cell: (row) => (
          <Button
            variant="danger"
            onClick={() => showDeleteModal(row._id)}
            id={row._id}
          >
            {t("delete")}
            <i className="fa fa-trash mx-1" />
          </Button>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
      {
        name: t("the status"),
        cell: (row) =>
          row.status === true ? (
            <Button
              variant="danger"
              onClick={() => {
                handleShowFun(row._id, false);
              }}
              id={row._id}
            >
              {t("block")}
              <i className="fa fa-lock mx-1" />
            </Button>
          ) : (
            <Button
              variant="success"
              onClick={() => {
                handleShowFun(row._id, true);
              }}
              id={row._id}
            >
              {t("active")}
              <i className="fa fa-unlock mx-1" />
            </Button>
          ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      }
    );
  }

  console.log("allSubscription");
  console.log(allSubscription);
  const tableData = {
    columns,
    data: allSubscription,
  };

  return (
    <div>
      <Helmet>
        <title>
          {` ${t("Subscriptions")} -${
            Cookies.get("i18next") == "ar"
              ? settingsRes.data?.titleAr
              : settingsRes.data?.titleEn
          }`}{" "}
        </title>
      </Helmet>

      {/********** Modal To Delete The subscription *****************/}
      <Modal show={deleteModal} onHide={closeDeleteModal}>
        <Modal.Header>
          <Modal.Title>
            <div className="font">{t("Confirm deletion")} </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="font">
            {t("Are you sure about the deletion process?")}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="font"
            variant="secondary"
            onClick={closeDeleteModal}
          >
            {t("retreat")}
          </Button>
          <Button
            className="font"
            variant="danger"
            onClick={deleteSubscription}
          >
            {t("delete")}
          </Button>
        </Modal.Footer>
      </Modal>
      {/************************************************************************/}

      <div className="d-flex justify-content-between">
        <div className="admin-content-text"> {t("Manage Subscriptions")}</div>

        {showAddComponnet && (
          <AddButton
            title={t("Add Subscription")}
            navigateTo={`/admin/subscriptions/add?storeIdRes=${storeIdRes}`}
          />
        )}
      </div>

      {showSelectStoreComponent === true && <SuperAdminSelectStoreComponent />}

      <DataTableExtensions {...tableData} filterPlaceholder={t("search here")}>
        <DataTable
          noHeader
          defaultSortAsc={false}
          pagination
          highlightOnHover
        />
      </DataTableExtensions>
      <ToastContainer />
    </div>
  );
};
export default AdminSubscription;
