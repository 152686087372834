/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import mobile from "../../Images/mobile.png";
import LeftButton from "./LeftButton";
import RightButton from "./RightButton";
// import RightButton from './RightButton';
import { useParams } from "react-router-dom";
import withRouter from './withRouter';
import axios from "axios";
import baseURL from "../../Api/baseURL";

const PREFIX_URL = 'https://raw.githubusercontent.com/xiaolin/react-image-gallery/master/static/';

class ProductGallary extends React.Component {
  constructor() {
    super();
    this.state = {
      showIndex: false,
      showBullets: true,
      infinite: true,
      showThumbnails: true,
      showFullscreenButton: true,
      showGalleryFullscreenButton: true,
      showPlayButton: true,
      showGalleryPlayButton: true,
      showNav: true,
      isRTL: false,
      slideDuration: 450,
      slideInterval: 2000,
      slideOnThumbnailOver: false,
      thumbnailPosition: 'bottom',
      showVideo: {},
      useWindowKeyDown: true,
      myImages: [],
      idd:''
    };

    this.images = [
    ]
  }

  componentDidMount() {
    this.state.idd = this.props.params.id
    // console.log('______________________')
    // console.log(this.props.params.id)
    baseURL.get(`/api/v1/products/${this.props.params.id}`)
      .then(res => {
        // console.log(res.data.images)
        let videoLink = res.data.video.split('v=');
        // console.log('res.data.images')
        // console.log(res.data.images)
        let mappedArr
        if(res.data.images.length !== 0){
          // console.log('firsttttt')
           mappedArr = res.data.images.map((img) => {
            return {
              original: img,
              thumbnail: img,
              originalClass: 'featured-slide',
              thumbnailClass: 'featured-thumb',
              description: '',
            };
          });
        }else {
          res.data.images = [""]
          mappedArr = res.data.images.map((img) => {
            //  console.log('fff')
            return {
              original:img!==""?img:"http://shelly-ksa.com/public/uploads/shellyLogo.jpg-1674397778957.jpeg",
              thumbnail:img!==""?img: "http://shelly-ksa.com/public/uploads/shellyLogo.jpg-1674397778957.jpeg",
              originalClass: 'featured-slide',
              thumbnailClass: 'featured-thumb',
              description: '',
            };
          });
        }
       

        if (res.data.video) {
          this.images = [
            ...mappedArr,
            {
              thumbnail: 'http://shelly-ksa.com/public/uploads/youtube.jpg',
              original: 'http://shelly-ksa.com/public/uploads/youtube.jpg',
              embedUrl: `https://www.youtube.com/embed/${videoLink[1]}?autoplay=1&showinfo=0`,
              description: 'show video',
              renderItem: this._renderVideo.bind(this)
            },
          ]
        } else {
          this.images = [...mappedArr]
          // console.log('this.images')
          // console.log(this.images)
        }
      })

  }

   
  
  componentDidUpdate(){

    // console.log(this.state.idd)
    
    // console.log(this.props.params.id)
    // console.log('______________________')

    if(this.state.idd !== this.props.params.id){
      this.state.idd = this.props.params.id

      // console.log('______________________')
      // console.log(this.props.params.id)


      baseURL.get(`/api/v1/products/${this.props.params.id}`)
      .then(res => {
        // console.log(res.data.images)
        let videoLink = res.data.video.split('v=');
        // console.log('res.data.images')
        // console.log(res.data.images)
        let mappedArr
        if(res.data.images.length !== 0){
          // console.log('firsttttt')
           mappedArr = res.data.images.map((img) => {
            return {
              original: img,
              thumbnail: img,
              originalClass: 'featured-slide',
              thumbnailClass: 'featured-thumb',
              description: '',
            };
          });
        }else {
          res.data.images = [""]
          mappedArr = res.data.images.map((img) => {
            //  console.log('fff')
            return {
              original:img!==""?img:"http://shelly-ksa.com/public/uploads/shellyLogo.jpg-1674397778957.jpeg",
              thumbnail:img!==""?img: "http://shelly-ksa.com/public/uploads/shellyLogo.jpg-1674397778957.jpeg",
              originalClass: 'featured-slide',
              thumbnailClass: 'featured-thumb',
              description: '',
            };
          });
        }
      

        if (res.data.video) {
          this.images = [
            ...mappedArr,
            {
              thumbnail: 'http://shelly-ksa.com/public/uploads/youtube.jpg',
              original: 'http://shelly-ksa.com/public/uploads/youtube.jpg',
              embedUrl: `https://www.youtube.com/embed/${videoLink[1]}?autoplay=1&showinfo=0`,
              description: 'show video',
              renderItem: this._renderVideo.bind(this)
            },
          ]
        } else {
          this.images = [...mappedArr]
          // console.log('this.images')
          // console.log(this.images)
        }
      })

    }

   
  }

  _onImageClick(event) {
    // console.debug('clicked on image', event.target, 'at index', this._imageGallery.getCurrentIndex());
  }

  _onImageLoad(event) {
    // console.debug('loaded image', event.target.src);
  }

  _onSlide(index) {
    this._resetVideo();
    // console.debug('slid to index', index);
  }

  _onPause(index) {
    // console.debug('paused on index', index);
  }

  _onScreenChange(fullScreenElement) {
    // console.debug('isFullScreen?', !!fullScreenElement);
  }

  _onPlay(index) {
    // console.debug('playing from index', index);
  }

  _handleInputChange(state, event) {
    if (event.target.value > 0) {
      this.setState({ [state]: event.target.value });
    }
  }

  _handleCheckboxChange(state, event) {
    this.setState({ [state]: event.target.checked });
  }

  _handleThumbnailPositionChange(event) {
    this.setState({ thumbnailPosition: event.target.value });
  }

  _getStaticImages() {
    let images = [];
    for (let i = 2; i < 12; i++) {
      images.push({
        original: `${PREFIX_URL}${i}.jpg`,
        thumbnail: `${PREFIX_URL}${i}t.jpg`
      });
    }

    return images;
  }

  _resetVideo() {
    this.setState({ showVideo: {} });

    if (this.state.showPlayButton) {
      this.setState({ showGalleryPlayButton: true });
    }

    if (this.state.showFullscreenButton) {
      this.setState({ showGalleryFullscreenButton: true });
    }
  }

  _toggleShowVideo(url) {
    const showVideo = this.state;
    this.setState({
      showVideo: {
        ...showVideo,
        [url]: !showVideo[url]
      }
    });

    if (!showVideo[url]) {
      if (this.state.showPlayButton) {
        this.setState({ showGalleryPlayButton: false });
      }

      if (this.state.showFullscreenButton) {
        this.setState({ showGalleryFullscreenButton: false });
      }
    }
  }

  _renderVideo(item) {
    return (
      <div>
        {
          this.state.showVideo[item.embedUrl] ?
            <div className='video-wrapper'>
              <a
                className='close-video'
                onClick={this._toggleShowVideo.bind(this, item.embedUrl)}
              >
              </a>
              <iframe
                width='560'
                height='315'
                src={item.embedUrl}
                frameBorder='0'
                allowFullScreen
              >
              </iframe>
            </div>
            :
            <a onClick={this._toggleShowVideo.bind(this, item.embedUrl)}>
              <div className='play-button'></div>
              <img className='image-gallery-image' alt="image_galry" src={item.original} />
              {
                item.description &&
                <span
                  className='image-gallery-description'
                  style={{ right: '0', left: 'initial' }}
                >
                  {item.description}
                </span>
              }
            </a>
        }
      </div>
    );
  }

  render() {
    return (
      <section className='app'>
        <ImageGallery
          ref={i => this._imageGallery = i}
          items={this.images}
          onClick={this._onImageClick.bind(this)}
          onImageLoad={this._onImageLoad}
          onSlide={this._onSlide.bind(this)}
          onPause={this._onPause.bind(this)}
          onScreenChange={this._onScreenChange.bind(this)}
          onPlay={this._onPlay.bind(this)}
          infinite={this.state.infinite}
          showBullets={this.state.showBullets}
          showFullscreenButton={this.state.showFullscreenButton && this.state.showGalleryFullscreenButton}
          showPlayButton={this.state.showPlayButton && this.state.showGalleryPlayButton}
          showThumbnails={this.state.showThumbnails}
          showIndex={this.state.showIndex}
          showNav={this.state.showNav}
          isRTL={this.state.isRTL}
          thumbnailPosition={this.state.thumbnailPosition}
          slideDuration={parseInt(this.state.slideDuration)}
          slideInterval={parseInt(this.state.slideInterval)}
          slideOnThumbnailOver={this.state.slideOnThumbnailOver}
          additionalClass="app-image-gallery"
          useWindowKeyDown={this.state.useWindowKeyDown}
        />
      </section>
    );
  }
}

export default withRouter(ProductGallary);
