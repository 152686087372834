import React from "react";
import { Container} from "react-bootstrap";

import ContactUs from "../../Components/Utilities/ContactUs"

const ContactusPage = () => {
  return (
    <div>
    <ContactUs/>
    </div>

  );
};

export default ContactusPage;
