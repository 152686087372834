import React from 'react';
import { useTranslation } from "react-i18next";


function SubscriptionEndComponent() {
    const { t } = useTranslation();
  return (
    <div
    className="d-flex vh-100 vw-100 justify-content-center align-items-center"
    >
   <h5>{t("subscritionEnded")}</h5> 
    </div>
  )
}

export default SubscriptionEndComponent