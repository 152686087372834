import React, { useEffect, useState } from "react";
import { createBrand } from "../../redux/actions/brandAction";
import { useDispatch, useSelector } from "react-redux";
import notify from "../../hook/useNotification";
import {
  createReview,
  viewReviewsByProductId,
} from "../../redux/actions/reviewActions";
import { getSettings} from "../../redux/actions/settingsAcions"



const ViewAllReviewHook = (id) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const allReviewByProd = useSelector(
    (state) => state.reviewReducer.reviewsByProduct
  );
  useEffect(() => {
    setLoading(true);
    ///
    dispatch(viewReviewsByProductId(id, 1, 5));
    setLoading(false);
  }, []);

  const onPress = async (page) => {
    await dispatch(viewReviewsByProductId(id, page, 5));
  };


  const settings = useSelector((state) => state.settingsReducers.settings);
  // console.log('sssssssettingsssssssss')
  const showReviews = settings?settings.data?.showReviews:''
  // console.log(showReviews)


  return [allReviewByProd, onPress , showReviews];
};

export default ViewAllReviewHook;
