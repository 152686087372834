import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";


const SubTitle = ({ title, btntitle, path }) => {

  const settingsRes = useSelector((state) => state.settingsReducers.settings);
  return (
    <div className="d-flex justify-content-between pt-2">
      <div className="sub-tile" style={{ color: settingsRes?.data?.primaryColor }} >{title}</div>
      {btntitle ? (
        <Link to={`${path}`} style={{ textDecoration: "none" }}>
          <div className="shopping-now" style={{ color: settingsRes?.data?.primaryColor, borderColor: settingsRes?.data?.primaryColor }} >{btntitle}</div>
        </Link>
      ) : null}
    </div>
  );
};

export default SubTitle;
