import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import notify from "../useNotification";
import { createNewStore, createNewUser } from "../../redux/actions/authActions";
import { getAllRefCodes } from "../../redux/actions/refCodesActions";

const ResgisterStoreHook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  

  const [firstname, setFirstname] = useState("");
  const [storeName, setStoreName] = useState("");
  const [storeNameAr, setStoreNameAr] = useState("");

  const [lastname, setLastname] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const[isMultiVendor,setIsMultiVendor]=useState(false);
  const [loading, setLoading] = useState(true);
  //التاجر لازم يوافق علي الشروط والاحكام قبل تسجيل المتجر
  const[agreeTerms,setAgreeTerms]=useState(location.pathname=='/registerstore'? false :true);


  const[refCode,setRefCode]=useState("");
  console.log('location.pathname');
  console.log(location.pathname);
  //const {id}=useParams();
  const[searchParams,setSearchParams] =useSearchParams();
  let code= searchParams.get('refCode');
  useEffect(()=>{
    const get=async()=>{
      await dispatch(getAllRefCodes(true));
    }
    get();
   

  //  console.log('code');
  //  console.log(code);
   if(code==null) return;
   setRefCode(code);
  },[]);
  
  const activeRefCodes=useSelector((state)=>state.refCodesReducer.allRefCodes);
  console.log('activeRefCodes');
  console.log(activeRefCodes.data);
  
   let foundObj=activeRefCodes?.data?.find(c=>c.code==code);
    console.log(foundObj);
  // foundObj&&console.log(Object.keys(foundObj).length)
  let user=localStorage.getItem('user');
  user=JSON.parse(user);

  const onChangeMultiVendor=(e)=>{
    setIsMultiVendor(e.target.value);
  }
  const onChangeFirstname = (e) => {
    setFirstname(e.target.value);
  };
  const onChangeStoreName = (e) => {
    setStoreName(e.target.value);
  };
  const onChangeStoreNameAr = (e) => {
    setStoreNameAr(e.target.value);
  };
  const onChangeLastname = (e) => {
    setLastname(e.target.value);
  };
  const onChangeMobile = (e) => {
    setMobile(e.target.value);
  };
  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };
  const onChangeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };
  // const validationValues = () => {};
  const res = useSelector((state) => state.authReducers.newStore);

  const onSubmit = async () => {
    if(agreeTerms==false){
      notify('يجب الموافقة علي الشروط الاحكام قبل تسجيل المتجر','error');
      return;
    }

    if(location.pathname=='/registerstore'){
      if(refCode==''){
        notify('يجب ادخال كود المتجر','error');
        return;
      }

      let foundObj=activeRefCodes?.data?.find(c=>c.code==code);
      console.log(foundObj);
      
      if(!foundObj?._id){
        notify('خطأ في الكود المرجعي برجاء التواصل مع الادارة','error');
        return;
      }
      
      
    }
  //   let foundObj=activeRefCodes?.data?.find(c=>c.code==code);

  // foundObj&&console.log(Object.keys(foundObj).length)
    if (storeName === "") {
      notify("من فضلك ادخل إسم مميز للمتجر", "error");
      return;
    }
    if (storeNameAr === "") {
      notify("من فضلك ادخل إسم المتجر ", "error");
      return;
    }
    if (firstname === "") {
      notify("من فضلك ادخل الاسم الاول", "error");
      return;
    }
    if (lastname === "") {
      notify("من فضلك ادخل الاسم الاخير", "error");
      return;
    }
    if (mobile.length <= 9) {
      notify("من فضلك ادخل رقم الجوال صحيح", "error");
      return;
    }
    if (email === "") {
      notify("من فضلك ادخل البريد الالكتروني", "error");
      return;
    }
    if (password === "") {
      notify("من فضلك ادخل كلمة المرور", "error");
      return;
    }
    if (confirmPassword === "") {
      notify("من فضلك ادخل تاكيد كلمة المرور", "error");
      return;
    }
    if (password !== confirmPassword) {
      notify("من فضلك تاكد من كلمه المرور", "error");
      return;
    } else {
      setLoading(true);
      await dispatch(
        createNewStore({
          name: storeName.trim(),
          nameAr: storeNameAr,
          firstname: firstname,
          lastname: lastname,
          mobile: mobile,
          email: email.toLocaleLowerCase(),
          password: password,
          MULTI_VENDOR:isMultiVendor,
           refCode:refCode
        })
      );
      setLoading(false);
    }
  };
  useEffect(() => {
    if (loading === false) {
      if (res) {
        if (res.token) {
          localStorage.setItem("token", res.token);
          notify("تم تسجيل المتجر بنجاح", "success");
          // setLoading(true);
          setTimeout(() => {
            if(user && user.role==='superAdmin')navigate('/admin/allstores');
          else  window.location.href = `/${res.store.name}/login`;
          }, 2000);
        }
        console.log("ressssssssssssss");
        console.log(res);
        if (res.data) {
          console.log("res.data");
          console.log(res.data);
          if (res.data === "Store Alredy Exist") {
            notify("اسم المتجر موجود بالفعل برجاء ادخال اسم مميز", "error");
          }
          if (res.data.msg === "email or mobile already exists") {
            notify(" البريد الالكتروني أو رقم الجوال موجودين بالفعل", "error");
          }
        }
      }
    }
  }, [loading]);

  return [
    firstname,
    lastname,
    mobile,
    email,
    password,
    confirmPassword,
    loading,
    onChangeFirstname,
    onChangeLastname,
    onChangeMobile,
    onChangeEmail,
    onChangePassword,
    onChangeConfirmPassword,
    onSubmit,
    storeName,
    storeNameAr,
    onChangeStoreName,
    onChangeStoreNameAr,
    onChangeMultiVendor,
    agreeTerms,setAgreeTerms,
    refCode,setRefCode,
    isMultiVendor
  ];
};

export default ResgisterStoreHook;
