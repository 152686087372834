import {
  CREATE_NEW_SLIDER,
  GET_ALL_SLIDER,
  UPDATE_SLIDER,
  GET_ONE_SLIDER,
  ALL_SUBSCRIPTIONS,
  CREATE_NEW_SUBSCRIPTION,
  PAYMENT_SUBSCRIPTION,
  LAST_SUBSCRIPTION,
  LAST_SUBSCRIPTION_LOADING_START,
  GET_LAST_SUBSCRIPTION_BY_ADMIN
} from "../type";
const inital = {
  newSubscription: [],
  allSubscriptions: [],
  paymentSubscription: [],
  lastSubscription: [],
  lastSubscriptionForAdmin:[],
  loading:false
};

const subscriptionReducer = (state = inital, action) => {
  switch (action.type) {
    case CREATE_NEW_SUBSCRIPTION:
      return {
        ...state,
        newSubscription: action.payload,
      };
    case ALL_SUBSCRIPTIONS:
      return {
        ...state,
        allSubscriptions: action.payload,
      };

    case LAST_SUBSCRIPTION_LOADING_START:
      return{
        ...state,
        loading: true,
      }

    case LAST_SUBSCRIPTION:
      return {
        ...state,
        lastSubscription: action.payload,
        loading:false
      };
    case GET_LAST_SUBSCRIPTION_BY_ADMIN:
      return{
        ...state,
        lastSubscriptionForAdmin:action.payload,
        loading:false
      };
    case PAYMENT_SUBSCRIPTION:
      return {
        ...state,
        paymentSubscription: action.payload,
      };
    default:
      return state;
  }
};
export default subscriptionReducer;
