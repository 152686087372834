import { useInsertData } from "../../hooks/useInsertData.js";
import {
  ADD_VENDOR,
  GET_PRODUCTS_BY_VENDOR_ID,
  GET_ORDERS_BY_VENDOR_ID,
  GET_VENDOR_BY_USERNAME,
} from "../type";
import { useGetDataToken } from "../../hooks/useGetData";

// Add Vendor by Admin
export const addVendor = (body) => async (dispatch) => {
  try {
    const response = await useInsertData(`/api/v1/users/admin/addvendor`, body);
    dispatch({
      type: ADD_VENDOR,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: ADD_VENDOR,
      payload: error.response,
    });
  }
};
export const getVendorByusername = (username,selectedStore) => async (dispatch) => {
  try {
    const response = await useGetDataToken(`/api/v1/users/vendor/${username}?storeId=${selectedStore}`);
    dispatch({
      type: GET_VENDOR_BY_USERNAME,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: GET_VENDOR_BY_USERNAME,
      payload: error.response,
    });
  }
};

//get vendor Products by vendor Id
export const getAllVendorProductsById = (id) => async (dispatch) => {
  try {
    const response = await useGetDataToken(`/api/v1/products/vendor/${id}`);
    dispatch({
      type: GET_PRODUCTS_BY_VENDOR_ID,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: GET_PRODUCTS_BY_VENDOR_ID,
      payload: error.response,
    });
  }
};

//Get All Orders By Vendor Id
export const getAllOrdersVendorById = (id) => async (dispatch) => {
  try {
    const response = await useGetDataToken(`/api/v1/orders/vendor/order/${id}`);
    dispatch({
      type: GET_ORDERS_BY_VENDOR_ID,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: GET_ORDERS_BY_VENDOR_ID,
      payload: error.response,
    });
  }
};
