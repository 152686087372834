
import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import EditBrandHook from "../../hook/brand/EditBrandHook";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
const AdminEditBrand = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [
    image,
    nameAr,
    nameEn,
    BrandStatus,
    onChangeNameAr,
    onChangeNameEn,
    onChangeBrandStatus,
    onImageChange,
    handleSubmit
  ] = EditBrandHook(id)
  const settingsRes = useSelector((state) => state.settingsReducers.settings);
  return (
    <div>
      <Row className="justify-content-start ">
        <div className="admin-content-text pb-4"></div>
        <Col sm="8">
          <label htmlFor="image" className="d-block my-2"> {t("image")} </label>
          {/* <img src={avatar} alt="" height="100px" width="120px" /> */}
          <div>
            <label htmlFor="upload-photo" >
              <img
                id="image"
                src={image}
                alt="fzx"
                height="100px"
                width="120px"
                style={{ cursor: "pointer" }}
              />
            </label>
            <input
              type="file"
              name="photo"
              onChange={onImageChange}
              id="upload-photo"
            />
          </div>
          <label htmlFor="brandAr" className="d-block mt-3">{t("brand name in arabic")}</label>
          <input
            id="brandAr"
            onChange={onChangeNameAr}
            value={nameAr}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("brand name in arabic")}
          />
          <label htmlFor="brandEn" className="d-block mt-2">{t("brand name in english")}</label>
          <input
            id="brandEn"
            onChange={onChangeNameEn}
            value={nameEn}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("brand name in english")}
          />
          <label htmlFor="status" className="d-block mt-2"> {t("brand state")} </label>

          <select
            value={BrandStatus}
            onChange={onChangeBrandStatus}
            name="status"
            id="status"
            className="select input-form-area mt-2 px-3 "
          >
            <option value="0"> {t("the status")}</option>
            <option value="true">{t("active")}</option>
            <option value="false">{t("not active")}</option>
          </select>
        </Col>
      </Row>
      <Row>
        <Col sm="8" className="d-flex justify-content-end ">
          <button onClick={handleSubmit} className="btn-save d-inline mt-2 " style={{ backgroundColor: settingsRes?.data?.primaryColor }}>
            {t("save")}
          </button>
        </Col>
      </Row>

      <ToastContainer />
    </div>
  );
};

export default AdminEditBrand;
