import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers, updateUser } from "../../redux/actions/usersActions";
import { useNavigate } from "react-router-dom";
import {
  getWebsiteData,
  getSettings,
} from "../../redux/actions/settingsAcions";
import { getAllStores } from "../../redux/actions/storesActions";
import notify from "../useNotification";
import getStoreNameFromUrl from "../../Components/Utilities/getStoreNameFromUrl";

const storeName = getStoreNameFromUrl();

const ViewAllUsersHook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const[showModal,setShowModal]=useState(false);
  const[newPassword,setNewPassword]=useState('');

  const [showSelectStoreComponent, setShowSelectStoreComponent] =
    useState(false);
  const [showAddComponnet, setShowAddComponent] = useState(true);

  const selectedUser=useRef('');

  var user = "";
  if (localStorage.getItem("user") != null) {
    user = JSON.parse(localStorage.getItem("user"));

    console.log("user.role");
    console.log(user?.role);
  }

  const storeIdRes = useSelector(
    (state) => state.allStores.selectStoreBySuperAdmin
  );

  // console.log('storeIdRes')
  // console.log(storeIdRes);
  // useEffect(() => {
  //   if (storeIdRes !== "") {
  //     dispatch(getAllUsers(storeIdRes));
  //     setShowAddComponent(true);
  //   } else {
  //     setShowAddComponent(false);
  //   }
  // }, [storeIdRes, dispatch]);

  const handleShowFun = async (id, status) => {
    setLoading(true);
    await dispatch(
      updateUser(id, {
        status: status,
      })
    );
    setLoading(false);
  };
  const res = useSelector((state) => state.usersReducers.updateUser);

  useEffect(() => {
    if (loading === false) {
      if (res && res.success === true) {
        notify("تم التعديل بنجاح", "success");
        setTimeout(() => {
          if (storeIdRes !== "") {
            dispatch(getAllUsers(storeIdRes));
          } else {
            dispatch(getAllUsers(user?.storeId));
          }
        }, 1000);
      } else {
        notify("فشل في عملية التعديل", "error");
      }

      setShowModal(false);
      setNewPassword('');
      selectedUser.current='';
    }
  }, [loading]);

  useEffect(() => {
    //if(user.role==="superAdmin") dispatch(getAllStores());
    if (user.role === "superAdmin") {
      setShowSelectStoreComponent(true);
      if (storeIdRes !== "") {
        dispatch(getAllUsers(storeIdRes));
        setShowAddComponent(true);
      } else {
        setShowAddComponent(false);
        setUsers([]);
      }
    } else dispatch(getAllUsers(user?.storeId));
  }, [dispatch, user?.storeId, user.role, storeIdRes]);

  const allUsers = useSelector((state) => state.usersReducers.allUser);

  const webSiteData = useSelector(
    (state) => state.settingsReducers.webSiteData
  );
  const settings = useSelector((state) => state.settingsReducers.settings);
  const showReviews = settings.data?.showReviews;

  useEffect(() => {
    if (allUsers) {
      setUsers(allUsers);
    }
  }, [allUsers]);

  const clickHandler = (id) => {
    navigate(`/admin/edituser/${id}`);
  };

  function showModalHandler(row){
    setShowModal(true);
    selectedUser.current=row._id;
    
  }

  async function changePassword(){
    //console.log(selectedUser);
      setLoading(true);
    await dispatch(
      updateUser(selectedUser.current, {
        oldPassword:newPassword,
        password:newPassword
      })
    );
    setLoading(false);
  }

  return [
    users,
    clickHandler,
    webSiteData,
    handleShowFun,
    showReviews,
    settings,
    showSelectStoreComponent,
    showAddComponnet,
    showModal,setShowModal,
    showModalHandler,
    newPassword,setNewPassword,
    changePassword
  ];
};

export default ViewAllUsersHook;
