import SideBar from "../../Components/Admin/SideBar"
import EditOffer from "../../Components/Offers/EditOffer"

const EditOfferPage = () => {

    return (
        <div className="d-flex justify-content-start">
        <div>
          <SideBar />
        </div>
        <div className="p-4" style={{ width: "100%" }}>
        <EditOffer/>
        </div>
      </div>
    )

}

export default EditOfferPage