import { 
    GET_ALL_REF_CODES,
    CREATE_REF_CODE,
    UPDATE_RED_CODE
} from "../type";
import { useInsertData } from "../../hooks/useInsertData";
import { useGetData, useGetDataToken } from "../../hooks/useGetData";
import useDeleteData from "../../hooks/useDeleteData";
import { useUpdateData } from "../../hooks/useUpdateData";

export const getAllRefCodes=(status)=>async(dispatch)=>{
    try {
        let api='/api/v1/refCodes';
        if(status) api+=`?status=true`;
        const response=await useGetDataToken(api);
        dispatch({
            type:GET_ALL_REF_CODES,
            payload:response
        });
    } catch (error) {
        dispatch({
            type:GET_ALL_REF_CODES,
            payload:error.message
        });
    }
}

export const addRefCode=(body)=>async(dispatch)=>{
    try {
        const response=await useInsertData('/api/v1/refCodes',body);
        dispatch({
            type:CREATE_REF_CODE,
            payload:response
        })
    } catch (error) {
        dispatch({
            type:CREATE_REF_CODE,
            payload:error.message
        });
    }
}

export const updateRefCode=(id,body)=>async(dispatch)=>{
    try {
        const response=await useUpdateData(`/api/v1/refCodes/${id}`,body);
        dispatch({
            type:UPDATE_RED_CODE,
            payload:response
        })
    } catch (error) {
        dispatch({
            type:UPDATE_RED_CODE,
            payload:error.message
        });
    }
}