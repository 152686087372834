import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import CategoryHeader from "../../Components/Category/CategoryHeader";
import CardProductsContainer from "../../Components/Products/CardProductsContainer";
import Pagination from "../../Components/Utilities/Pagination";
import ProductsResults from "../../Components/Utilities/ProductsResults";
import SideFilter from "../../Components/Utilities/SideFilter";
//import ViewSearchOffersProductHook from "../../hook/product/ViewSearchOffersProductHook";
import ViewSearchProductHooks from "../../hook/product/ViewSearchProductHooks";
import LoadingSpinner from "../../Components/Utilities/LoadingSpinner";

const OfferProductPage = () => {
  // const [items, pagination, onPress, offers, result] =
  // ViewSearchOffersProductHook();
  // console.log(items);
  const [
    items,
    pagination,
    onPress,
    getProduct,
    result,
    offers,
    selectedCategorySearch,
    selectedBrandSearch,
    search,
    onChangeSetMinPrice,
    onChangeSetMaxPrice,
    minPrice,
    maxPrice,
    loadingSpinner,
  ] = ViewSearchProductHooks("");
  if (pagination) var pageCount = pagination;
  else pageCount = 0;

  return (
    <div style={{ minHeight: "670px" }}>
      <CategoryHeader />
      <Container>
        <ProductsResults onClick={offers} title={`${result} نتيجة بحث`} />
        <Row className="d-flex justify-content-center">
          <Col sm="2"  md="2" xs="3" className="d-flex ">
            <SideFilter
              props={{
                selectedCategorySearch,
                selectedBrandSearch,
                search,
                onChangeSetMinPrice,
                onChangeSetMaxPrice,
              }}
            />
          </Col>
          <Col md="10" sm="10" xs="9">

          {loadingSpinner === true ? (
            <LoadingSpinner />
          ) : (
            <CardProductsContainer products={offers} title="" btntitle="" />
          )}


            
          </Col>
        </Row>
        <Pagination pageCount={pageCount} onPress={onPress} />
      </Container>
    </div>
  );
};

export default OfferProductPage;
