import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import CategoryHeader from "../../Components/Category/CategoryHeader";
import CardProductsContainer from "../../Components/Products/CardProductsContainer";
import Pagination from "../../Components/Utilities/Pagination";
import ProductsResults from "../../Components/Utilities/ProductsResults";
import SideFilter from "../../Components/Utilities/SideFilter";
import ViewAllProductsByCategoryHook from "../../hook/product/ViewAllProductsByCategoryHook";
import ViewAllProductsBySubCategoryHook from "../../hook/product/ViewAllProductsBySubCategoryHook";
import ViewSearchProductHooks from "../../hook/product/ViewSearchProductHooks";

import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import LoadingSpinner from "../../Components/Utilities/LoadingSpinner";

const ProductsBySubCategory = () => {
  const { id } = useParams();
  const [catById] = ViewAllProductsBySubCategoryHook(id);
  // console.log('catById')
  // console.log(catById)
  const [
    items,
    pagination,
    onPress,
    getProduct,
    result,
    offers,
    selectedCategorySearch,
    selectedBrandSearch,
    search,
    onChangeSetMinPrice,
    onChangeSetMaxPrice,
    minPrice,
    maxPrice,
    loadingSpinner,
  ] = ViewSearchProductHooks("", id);
  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  if (pagination) var pageCount = pagination;
  else pageCount = 0;
  let hideCategory = true;
  return (
    <div style={{ minHeight: "670px" }}>
      <Helmet>
        <title>
          {`  ${items[0]?.subCategory.nameAr || " "} ${items[0] ? "-" : " "} ${
            settingsRes.data?.titleAr
          }`}{" "}
        </title>
      </Helmet>
      <CategoryHeader />
      <div
        style={{
          background: settingsRes?.data?.secondaryColor,
          height: "395px",
        }}
      >
        <div
          className="d-flex justify-content-center"
          style={{
            height: "370px",
            alignItems: "center",
          }}
        >
          {catById?.image ? (
            <img
              alt="vategory"
              src={catById?.image}
              width="260px"
              className="mx-3"
            />
          ) : null}
          <div style={{ fontWeight: "bold", fontSize: "24px" }}>
            {catById?.nameAr}
          </div>
        </div>
      </div>
      <Container>
        <Row className="d-flex justify-content-center">
          <Col md="2" sm="2" className="d-flex ">
            <SideFilter
              props={{
                selectedCategorySearch,
                selectedBrandSearch,
                search,
                onChangeSetMinPrice,
                onChangeSetMaxPrice,
                hideCategory,
              }}
            />
          </Col>
          <Col md="10" sm="10" xs="12" >
            {loadingSpinner === true ? (
              <LoadingSpinner />
            ) : (
              <CardProductsContainer products={items} title="" btntitle="" />
            )}
          </Col>
        </Row>
        <Pagination pageCount={pageCount} onPress={onPress} />
      </Container>
    </div>
  );
};

export default ProductsBySubCategory;
