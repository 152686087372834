import React from "react";
import { Col, Row } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import TermsPolicyAboutHook from "../../hook/termsPolicyAbout/TermsPolicyAboutHook";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { Helmet } from "react-helmet";
import showComponentOrSelectStore from "../Utilities/showComponentOrSelectStore";
import SuperAdminSelectStoreComponent from "../Utilities/SuperAdminSelectStoreComponent";

const AdminAbout = () => {
  const { t } = useTranslation();
  const [
    nameAr,
    nameEn,
    onChangeNameAr,
    onChangeNameEn,
    onSubmit,
    settingsRes,
    showSelectStoreComponent,
    storeIdRes,
  ] = TermsPolicyAboutHook(3);

  let showFlag = false;

  showFlag = showComponentOrSelectStore(showSelectStoreComponent, storeIdRes);

  console.log("showFlag");
  console.log(showFlag);

  return (
    <div>
      <Helmet>
        <title>
          {` ${t("about")}  ${
            Cookies.get("i18next") === "ar"
              ? settingsRes.data?.titleAr
              : settingsRes.data?.titleEn
          }`}{" "}
        </title>
      </Helmet>

      {showSelectStoreComponent && <SuperAdminSelectStoreComponent />}

      <Row className="justify-content-start ">
        <div className="admin-content-text pb-4">{t("about us")} </div>

        {showFlag == "true" && (
          <>
            <Col sm="8">
              <textarea
                value={nameAr}
                onChange={onChangeNameAr}
                className="input-form-area p-2 mt-3"
                rows="9"
                cols="50"
                placeholder={t("About Us (in Arabic)")}
              />
              <textarea
                value={nameEn}
                onChange={onChangeNameEn}
                className="input-form-area p-2 mt-3"
                rows="9"
                cols="50"
                placeholder={t("About Us (in English)")}
              />
            </Col>
          </>
        )}
      </Row>
      <Row>
        {showFlag == "true" && (
          <>
            <Col sm="8" className="d-flex justify-content-end ">
              <button
                onClick={onSubmit}
                className="btn-save d-inline mt-2 "
                style={{ backgroundColor: settingsRes?.data?.primaryColor }}
              >
                {t("save")}
              </button>
            </Col>
          </>
        )}
      </Row>
      <ToastContainer />
    </div>
  );
};

export default AdminAbout;
