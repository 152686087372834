import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllStores,
  setStoreIdBySuperAdmin,
} from "../../redux/actions/storesActions";
import { useEffect, useState } from "react";

export default function SuperAdminSelectStoreComponent() {
  const user = JSON.parse(localStorage.getItem("user"));

  const [selectedStore, setSelectedStore] = useState("");

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllStores());
  }, []);

  const { t } = useTranslation();
  const allStoresResponse = useSelector((state) => state.allStores.allStores);

  const storeIdRes = useSelector(
    (state) => state.allStores.selectStoreBySuperAdmin
  );

  console.log('storeIdRes');
  console.log(storeIdRes);


  console.log("allStoresResponse");
  console.log(allStoresResponse);

  const changeStore = (e) => {
    // console.log("e.target.value");
    // console.log(e.target.value);
    //console.log('dooooooooo');
    setSelectedStore(e.target.value);
    dispatch(setStoreIdBySuperAdmin(e.target.value));
  };
  return (
    <div className="mt-3 mb-3 w-75">
      <select
        id="select-store"
        name="select-store"
        className="select input-form-area  mt-3 px-2"
        onChange={changeStore}
        value={storeIdRes}
      >
        <option value="">{t("select store")}</option>
        {allStoresResponse&&allStoresResponse
          .filter((el) => el._id !== user?.storeId)
          .map((store) => (
            <option className="fw-bold text-black" key={store._id} value={store._id}>
              {store.name}
            </option>
          ))}
      </select>
    </div>
  );
}
