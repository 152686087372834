import useDeleteData from "../../hooks/useDeleteData";
import { useGetData, useGetDataToken } from "../../hooks/useGetData";
import { useInsertData } from "../../hooks/useInsertData";
import { useUpdateData } from "../../hooks/useUpdateData";
import { ADD_TICKET ,GET_ALL_TICKETS , UPDATE_TICKET } from "../type";



export const addContact =  (body) => async(dispatch) => {

    try {
        const response  = await useInsertData(`/api/v1/tickets`, body);
        dispatch({
            type:ADD_TICKET,
            payload:response
        })
        
    } catch (error) {
        dispatch({
            type:ADD_TICKET,
            payload:error.response
        })
    }

}

export const getAllTickets = (storeId) => async (dispatch) => {
    try {
        const response = await useGetDataToken(`/api/v1/tickets?storeId=${storeId}`);
        // console.log(response)
        dispatch({
            type:GET_ALL_TICKETS,
            payload:response
        })

    } catch (error) {
        
        dispatch({
            type:GET_ALL_TICKETS,
            payload:error.response
        })
    }
}

export const updateTicket = (id,body) => async (dispatch) => {
    try {
        const response = await useUpdateData(`/api/v1/tickets/${id}`,body);
        dispatch({
            type:UPDATE_TICKET,
            payload:response
        })

    } catch (error) {
        dispatch({
            type:UPDATE_TICKET,
            payload:error.response
        })
    }
}

