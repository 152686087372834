import React from "react";
import AdminEditShippingMethod from "../../Components/Admin/AdminEditShippingMethod";
import SideBar from "../../Components/Admin/SideBar";

const AdminEditShippingMethodPage = () => {
    return (
        <div className="d-flex justify-content-start">
            <div>
                <SideBar />
            </div>
            <div className="p-4" style={{ width: '100%' }}>
                <AdminEditShippingMethod />
            </div>
        </div>

    );
};

export default AdminEditShippingMethodPage;

