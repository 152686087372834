import React, { useState, useEffect } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import OrderPayCashHook from "../../hook/checkout/OrderPayCashHook";
import ViewAddressHook from "../../hook/user/ViewAddressHook";
import { ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import AddAddressHook from "../../hook/user/AddAddressHook";
import { useTranslation } from "react-i18next";
import UserMapLocation from "../User/UserMapLocation";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const PaymentMethod = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const [res] = ViewAddressHook();
  const [
    handleChooseAddress,
    createOrderCash,
    payments,
    totalPriceData,
    handlePaymentMethods,
  ] = OrderPayCashHook(parseInt(location?.state?.shippingCost) || 0);
  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  const currencytype =
    Cookies.get("i18next") === "ar"
      ? settingsRes.data?.defaultCurrancy.ar
      : settingsRes.data?.defaultCurrancy.en;

  const [
    name,
    zone,
    city,
    address,
    mobile,
    closeSign,
    onChangeName,
    onChangeZone,
    onChangeCity,
    onChangeAddress,
    onChangeMobile,
    onChangeCloseSign,
    onSubmit,
    show,
    handleShow,
    handleClose,
    latitude,
    longitude,
    onChangeLatLang,
    showAddressDetails,
  ] = AddAddressHook(2);

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <div style={{ width: "100%" }}>
          <Modal.Header>
            <Modal.Title>
              <div className="admin-content-text font">
                {" "}
                {t("add new address")}{" "}
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Row>
                <Col sm="8" style={{ width: "100%" }}>
                  <label htmlFor="address">{t("Title name")}</label>
                  <input
                    id="address"
                    value={name}
                    onChange={onChangeName}
                    type="text"
                    className="input-form d-block mt-2 px-3"
                    placeholder={t("Title nam")}
                  />
                  {showAddressDetails ? (
                    <>
                      <label htmlFor="closeSign" className="mt-2">
                        {t("nearest mark")}
                      </label>
                      <textarea
                        id="closeSign"
                        value={closeSign}
                        onChange={onChangeCloseSign}
                        className="input-form-area p-2 mt-2"
                        rows="2"
                        cols="50"
                        placeholder={t("nearest mark")}
                      />
                      <label htmlFor="phone" className="mt-2">
                        {" "}
                        {t("phone number")}
                      </label>
                      <input
                        id="phone"
                        value={mobile}
                        onChange={onChangeMobile}
                        type="text"
                        className="input-form d-block mt-2 px-3"
                        placeholder={t("phone number")}
                      />
                      <label htmlFor="zone" className="mt-2">
                        {t("region")}
                      </label>
                      <input
                        id="zone"
                        value={zone}
                        onChange={onChangeZone}
                        type="text"
                        className="input-form d-block mt-2 px-3"
                        placeholder={t("region")}
                      />
                      <label htmlFor="city" className="mt-2">
                        {" "}
                        {t("city")}
                      </label>
                      <input
                        id="city"
                        value={city}
                        onChange={onChangeCity}
                        type="text"
                        className="input-form d-block mt-2  px-3"
                        placeholder={t("city")}
                      />
                    </>
                  ) : (
                    ""
                  )}
                  <label htmlFor="notes" className="mt-2">
                    {" "}
                    {t("Notes")}
                  </label>
                  <textarea
                    id="notes"
                    value={address}
                    onChange={onChangeAddress}
                    className="input-form-area p-2 my-2"
                    rows="4"
                    cols="50"
                    placeholder={t("Notes")}
                  />
                  {/* <iframe
         width="100%"
         height="250"
         id="gmap_canvas"
         src={`https://maps.google.com/maps?q=${latitude},${longitude}&iwloc=&output=embed`}
         frameborder="0"
         scrolling="no"
         marginheight="0"
         marginwidth="0"
       ></iframe> */}
                  <UserMapLocation
                    latitude={latitude}
                    longitude={longitude}
                    onPress={onChangeLatLang}
                  />
                </Col>
              </Row>

              <ToastContainer />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="font d-inline mt-2"
              variant="secondary"
              onClick={handleClose}
            >
              {t("retreat")}
            </Button>
            <button
              onClick={onSubmit}
              className="btn-save d-inline mt-2 "
              style={{ backgroundColor: settingsRes?.data?.primaryColor }}
            >
              {t("add adress")}
            </button>
          </Modal.Footer>
        </div>
      </Modal>

      <div>
        {payments.length !== 0 ? (
          <div className="admin-content-text pt-5">
            {t("choose payment method")}
          </div>
        ) : (
          <div className="admin-content-text pt-5">{t("choose address")} </div>
        )}
        <div className="user-address-card my-3 px-3">
          {payments.length>0
            ? payments.map((el) => {
                return (
                  <Row className="d-flex justify-content-between">
                    <Col xs="12" className="d-flex my-2">
                      <input
                        onChange={handlePaymentMethods}
                        style={{ cursor: "pointer" }}
                        name="group"
                        id="group2"
                        type="radio"
                        value={el._id}
                        className="mt-2"
                      />
                      <label
                        className="mx-2"
                        for="group2"
                        style={{ cursor: "pointer" }}
                      >
                        {el.nameAr}
                      </label>
                    </Col>
                  </Row>
                );
              })
            : null}
          <Row className="d-flex my-3 pt-3 justify-content-start  ">
            <Col xs="4" className="d-flex">
              <select
                name="address"
                id="address"
                className="select mt-1 px-2 "
                onChange={handleChooseAddress}
              >
                <option value="0">{t("choose address")}</option>
                {res.length >= 1
                  ? res.map((el) => {
                      return (
                        <option key={el._id} value={el._id}>
                          {el.name}
                        </option>
                      );
                    })
                  : null}
              </select>
            </Col>
            <Col sm="5" className="d-flex justify-content-start ">
              <button
                onClick={handleShow}
                className="btn-add-address"
                style={{
                  width: "fit-content",
                  padding: "10px",
                  color: "black",
                  backgroundColor: settingsRes?.data?.primaryColor,
                }}
              >
                {t("add new adress")}
              </button>
            </Col>
          </Row>
        </div>

        <Row>
          <Col xs="12" className="pb-5 d-flex justify-content-end ">
            <div className="product-price d-inline   border">
              {parseInt(totalPriceData) +
                (parseInt(location?.state?.shippingCost) || 0)}{" "}
              {currencytype}
            </div>
            <div
              onClick={createOrderCash}
              className="product-cart-add px-3 pt-2 d-inline me-2"
              style={{ backgroundColor: settingsRes?.data?.primaryColor }}
            >
              {t("Checkout")}
            </div>
          </Col>
        </Row>
      </div>
      <ToastContainer />
    </div>
  );
};

export default PaymentMethod;
