import React from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import LoadingBar from "react-top-loading-bar";
import ProductDescription from "./ProductDescription";
import ProductGallary from "./ProductGallary";

const ProductDetails = () => {
  return (
    <div>
      <Row className="py-3">
        <Col lg="4">
          <ProductGallary />
        </Col>
        <Col lg="8">
          <ProductDescription />
        </Col>
      </Row>
    </div>
  );
};

export default ProductDetails;
