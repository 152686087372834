import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import TermsPolicyAboutHook from "../../hook/termsPolicyAbout/TermsPolicyAboutHook";
import { getSettings } from "../../redux/actions/settingsAcions";
import { t } from "i18next";
import Cookies from "js-cookie";
import getStoreNameFromUrl from "../Utilities/getStoreNameFromUrl";

const storeName=getStoreNameFromUrl();

const AdmingoogleAnalytics = () => {
    const dispatch = useDispatch();
    const settingsRes = useSelector((state) => state.settingsReducers.settings);
    const [googleAnalyticsURL, setGoogleAnalyticsURL] = useState("");

    const [loading, setLoading] = useState(true);

    // useEffect(() => {
    //     const get = async () => {
    //         setLoading(true);
    //         await dispatch(getSettings());
    //         setLoading(false);
    //     };
    //     get();
    // }, []);


    useEffect(() => {
        if (settingsRes && settingsRes.data) {
            setGoogleAnalyticsURL(settingsRes.data.googleAnalyticsURL);
        }
    }, []);

    return (
        <div>

            <Helmet>
                <title>{` ${t("Google Analytics Report")} - ${Cookies.get("i18next") === "ar" ? settingsRes.data?.titleAr : settingsRes.data?.titleEn}`} </title>
            </Helmet>

            <Row className="justify-content-start ">
                <div className="admin-content-text pb-4">{t("Google Analytics Report")}</div>
                {
                    googleAnalyticsURL != '' ? <iframe width="100%" height="800"
                        src={googleAnalyticsURL}
                        allowfullscreen></iframe> : <h4>
                        {t(" Please enter the Google Analytics link")}
                        {' '}
                        <Link to={`/admin/settings`} className="mx-1">
                            {t("from here")}
                        </Link>
                    </h4>
                }

            </Row>
        </div>
    );
};

export default AdmingoogleAnalytics;
