import { useEffect, useState } from "react";
import { getAllRefCodes , addRefCode , updateRefCode } from "../../redux/actions/refCodesActions";
import { useDispatch , useSelector } from "react-redux";
import { useRef } from "react";
import notify from "../useNotification";

export default function AdminRefCodesHook () {
    const dispatch=useDispatch();
    const[codes,setCodes]=useState([]);
    const[showModal,setShowModal]=useState(false);
    const[name,setName]=useState('');
    const[code,setCode]=useState("");
    const firstRenderRef=useRef(true);

    useEffect(()=>{
        dispatch(getAllRefCodes());
    },[]);

    const {allRefCodes,newRefCode,updateRefCode:updatedCode}=useSelector((state)=>state.refCodesReducer);
    const settingsRes = useSelector((state) => state.settingsReducers.settings);

    useEffect(()=>{
       if(allRefCodes?.data) setCodes(allRefCodes?.data);
    },[allRefCodes]);

    async function addNewCode() {
       await dispatch(addRefCode({
            name,
            code
       }));
    }
   
    useEffect(()=>{
        if(firstRenderRef.current==true)
        {
           // firstRenderRef.current=false;
            return;
        }

        if(newRefCode.success==true){
            console.log("newRefCode");
            console.log(newRefCode);

            notify("تم الاضافة بنجاح", "success");
            // console.log('xxxxxxxxxxxxxxx');
            setCodes(prev=>[...prev,newRefCode.data]);
            setName("");
            setCode("");
            setShowModal(false);
        }
        else{
            notify("فشل في عملية الاضافة تأكد من اضافة كود غير مستخدم", "error");
        }
    },[newRefCode]);

    async function editCode(id,status) {
        await dispatch(updateRefCode(id,{status}));
    }

    useEffect(()=>{
        if(firstRenderRef.current==true)
        {
            firstRenderRef.current=false;
            return;
        }
        if(updatedCode.success==true)
        {
           let newCodes= codes.map((code)=>code._id==updatedCode.data._id? updatedCode.data : code);

           setCodes(newCodes);

           notify("تم التعديل بنجاح", "success");
        }
        else{
            notify("فشل في عملية التعديل", "error");
        }

    },[updatedCode]);

    

    return[
        codes,
        settingsRes,
        showModal,setShowModal,
        name,setName
        ,code,setCode,
        addNewCode,
        editCode
    ];
}