import React, { useEffect, useState } from "react";
import { Col, Container, Row , Modal, Button } from "react-bootstrap";
//import { Link } from "react-router-dom";
//import RegisterHook from "../../hook/auth/RegisterHook";
import { ToastContainer } from "react-toastify";
//import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ResgisterStoreHook from "../../hook/auth/ResgisterStoreHook";
import { Link, useNavigate } from "react-router-dom";
import getStoreNameFromUrl from "../../Components/Utilities/getStoreNameFromUrl";
//import { useHistory } from 'react-router-dom';

const StoreRegisterPage = () => {
  const [
    firstname,
    lastname,
    mobile,
    email,
    password,
    confirmPassword,
    loading,
    onChangeFirstname,
    onChangeLastname,
    onChangeMobile,
    onChangeEmail,
    onChangePassword,
    onChangeConfirmPassword,
    onSubmit,
    storeName,
    storeNameAr,
    onChangeStoreName,
    onChangeStoreNameAr,
    onChangeMultiVendor,
    agreeTerms,setAgreeTerms,
    refCode,setRefCode,
    isMultiVendor
  ] = ResgisterStoreHook();

  const[showModal,setShowModal]=useState(false);
  const[terms,setTerms]=useState('');

  useEffect(()=>{
    async function getTermsFromServer()
    {
       const response= await fetch("http://store-api.megastore-app.com:5001/terms.txt");
      //  console.log('terms');
      //   console.log(response);
      let termsText;
      if(response.ok) termsText= await response.text();
      setTerms(termsText);

    }

    getTermsFromServer();
  },[]);

  // function agreeTermsFunction(e)
  // {
  //     console.log('agreeTermsFunction');
  //     console.log(e.target);
  // }
  // const history = useHistory();

  console.log('ResgisterStoreHook');
  console.log(terms);
  //console.log(ResgisterStoreHook);

  //   const settingsRes = useSelector((state) => state.settingsReducers.settings);
  const { t } = useTranslation();
  //const navigate=useNavigate();

  // const storeNameURL = getStoreNameFromUrl();
  return (
    <Container>
        <Modal show={showModal} onHide={()=>setShowModal(false)}
        size="xl"
        >
        <Modal.Header>
          <Modal.Title>
            <div className="font">الشروط والاحكام </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="font" style={{ whiteSpace: 'pre-wrap' }}>
            {terms}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="font"
            variant="secondary"
            onClick={()=>setShowModal(false)}
          >
            {t("retreat")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Row className="py-5 d-flex justify-content-center">
        <Col sm="12" className="d-flex flex-column ">
          <label className="mx-auto title-login">
            {t("Create a new store")}
          </label>
          <input
            value={storeName}
            onChange={onChangeStoreName}
            placeholder=" إسم مميز المتجر..."
            type="text"
            className="user-input my-2 text-center mx-auto"
          />
          <input
            value={storeNameAr}
            onChange={onChangeStoreNameAr}
            placeholder=" إسم المتجر بالعربيه ..."
            type="text"
            className="user-input my-2 text-center mx-auto"
          />
          <input
            value={firstname}
            onChange={onChangeFirstname}
            placeholder="الاسم الاول..."
            type="text"
            className="user-input my-2 text-center mx-auto"
          />
          <input
            value={lastname}
            onChange={onChangeLastname}
            placeholder="الاسم الاخير..."
            type="text"
            className="user-input my-2 text-center mx-auto"
          />

          <input
            value={refCode}
            disabled={true}
            onChange={(e)=>setRefCode(e.target.value)}
            placeholder="كود المتجر"
            type="text"
            className="user-input my-2 text-center mx-auto"
          />

          <input
            value={email}
            onChange={onChangeEmail}
            placeholder="الايميل..."
            type="email"
            className="user-input my-2 text-center mx-auto"
          />

          
          <input
            value={mobile}
            onChange={onChangeMobile}
            placeholder="الهاتف..."
            type="phone"
            className="user-input my-2 text-center mx-auto"
          />

          <input
            value={password}
            onChange={onChangePassword}
            placeholder="كلمه السر..."
            type="password"
            className="user-input my-2 text-center mx-auto"
          />
          <input
            value={confirmPassword}
            onChange={onChangeConfirmPassword}
            placeholder="تاكيد كلمة السر ..."
            type="password"
            className="user-input my-2 text-center mx-auto"
          />

         <label htmlFor="multivendor" className="my-2 text-center mx-auto">
            نوع المتجر
          </label>
          <select 
          id="multivendor"
          className="user-input my-2 text-center mx-auto"
          onChange={onChangeMultiVendor}
          value={isMultiVendor}
          >
            <option value={true}>متجر متعدد التجار</option>
            <option value={false}>متجر لتاجر واحد</option>
          </select>

          <div className="d-flex mx-auto my-3">
            <input type="checkbox" value={agreeTerms} onChange={()=>setAgreeTerms(e=>!e)} />
            <label
            onClick={()=>setShowModal(true)} 
            className="mx-2" style={{textDecoration:'underline',cursor:'pointer'}}>اوافق علي الشروط والاحكام</label>
    
          </div>
            

          <div className="d-flex mx-auto mt-4 text-center">
          <button
            onClick={onSubmit}
            className="btn-login mx-2 btn btn-success"
            style={{width: "183px" }}
          >
            تسجيل الحساب
          </button>
          {/* window.location.href='/' */}
          <button
          onClick={()=>window.location.href='/'} 
          className="btn-login" 
          style={{ backgroundColor: "#000",width: "183px" }}
          >رجوع</button>
          </div>
          
        </Col>
      </Row>
      <ToastContainer />
    </Container>
  );
};

export default StoreRegisterPage;
