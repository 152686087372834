import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateUserPassword,
  updateUserProfile,
} from "../../redux/actions/authActions";
import notify from "../../hook/useNotification";
import { useNavigate } from "react-router-dom";
import {
  getShippingMethods,
  editShippingMehod,
  getOneShippingMehod,
} from "../../redux/actions/shippingMethodAction";
import {
  getWebsiteData,
  getSettings,
} from "../../redux/actions/settingsAcions";
import { getOneUser } from "../../redux/actions/usersActions";
import { useTranslation } from "react-i18next";
import getStoreNameFromUrl from "../../Components/Utilities/getStoreNameFromUrl";

const storeName=getStoreNameFromUrl();

const ProfileHook = () => {
  const navigate = useNavigate();
  let user = [];
  if (localStorage.getItem("user") != null)
    user = JSON.parse(localStorage.getItem("user"));

  const [firstname, setFirstname] = useState(user.firstname);
  const [lastname, setLastname] = useState(user.lastname);
  const [mobile, setMobile] = useState(user.mobile);
  const [email, setEmail] = useState(user.email);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showTable, setShowTable] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(true);

  const [wieghtNumber, setwieghtNumber] = useState("");
  const [wieghtCost, setwieghtCost] = useState("");
  const [wieghtExtraCost, setwieghtExtraCost] = useState("");
  const [shippingKey, setshippingKey] = useState("");
const [confirmPasswordDeleteProfile,setConfirmPasswordDeleteProfile] = useState('')
const [loadingPasswordProfile,setLoadingPasswordProfile] = useState(true)


  const [shippingId, setShippingId] = useState(0);

  const [showDelete, setShowDelete] = useState(false);
  const handleShowDelete = () => setShowDelete(true);
  const handleCloseDelete = () => setShowDelete(false)



  const dispatch = useDispatch();
const {t} = useTranslation()

  const onChangeConfirmPasswordDeleteProfile = (e) => {
    setConfirmPasswordDeleteProfile(e.target.value)
  }

  const onChangeFirstname = (e) => {
    e.persist();
    setFirstname(e.target.value);
  };
  const onChangeLastname = (e) => {
    e.persist();
    setLastname(e.target.value);
  };
  const onChangeMobile = (e) => {
    e.persist();
    setMobile(e.target.value);
  };
  const onChangeEmail = (e) => {
    e.persist();
    setEmail(e.target.value);
  };

  const onChangewieghtNumber = (e) => {
    e.persist();
    setwieghtNumber(e.target.value);
  };
  const onChangewieghtCost = (e) => {
    e.persist();
    setwieghtCost(e.target.value);
  };
  const onChangewieghtExtraCost = (e) => {
    e.persist();
    setwieghtExtraCost(e.target.value);
  };

  const handleSubmit = async () => {
    setLoading(true);
    await dispatch(
      updateUserProfile(user._id, {
        firstname: firstname,
        lastname: lastname,
        mobile: mobile,
        email: email,
      })
    );
    setLoading(false);

    setShow(false);
  };

  const res = useSelector((state) => state.authReducers.userProfile);
  useEffect(() => {
    if (loading === false) {
      if (res && res.success === true) {
        localStorage.setItem("user", JSON.stringify(res.data));
        notify("تم التعديل بنجاح", "success");
        setTimeout(() => {
          window.location.reload(false);
        }, 1500);
      } else {
        notify("فشل فيه عملية التحديث", "warn");
      }
    }
  }, [loading]);
  // change user password
  const [oldpassword, setOldpassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loadingPass, setLoadingPass] = useState("");

  const onChangeOldPassword = (e) => {
    e.persist();
    setOldpassword(e.target.value);
  };
  const onChangeNewPassword = (e) => {
    e.persist();
    setNewPassword(e.target.value);
  };
  const onChangeConfirmPassword = (e) => {
    e.persist();
    setConfirmPassword(e.target.value);
  };

  const changePassword = async () => {
    if (oldpassword === "" || newPassword === "" || confirmPassword === "") {
      notify("من فضلك اكمل البيانات", "error");
    } else if (newPassword !== confirmPassword) {
      notify("تاكيد البسورد غير صحيح", "error");
    } else {
      setLoadingPass(true);
      await dispatch(
        updateUserPassword(user._id, {
          oldPassword: oldpassword,
          password: newPassword,
        })
      );
      setLoadingPass(false);
    }
  };

  const resPassword = useSelector((state) => state.authReducers.userPassword);

  useEffect(() => {
    if (loadingPass === false) {
      if (resPassword && resPassword.success === true) {
        notify("تم تغير كلمة المرور بنجاح", "success");
        setTimeout(() => {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          navigate(`/login`);
        }, 1500);
      } else {
        notify(t("The password is incorrect"), "warn");
      }
    }
  }, [loadingPass]);

  // git All shipping methods
  const allShippingMethods = useSelector(
    (state) => state.shippingMethodReducer.shipping
  );

  const webSiteData = useSelector(
    (state) => state.settingsReducers.webSiteData
  );
  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  useEffect(() => {
    const get = async () => {
      await dispatch(getShippingMethods());
    };
    get();
  }, []);

  const oneUser = useSelector((state) => state.usersReducers.oneUser);

  useEffect(() => {
    if (oneUser && oneUser.shipping) {
      setShippingId(oneUser.shipping?._id);
      dispatch(getOneShippingMehod(oneUser.shipping?._id, user._id));
    }
  }, [oneUser]);

  useEffect(() => {
    const get = async () => {
      await dispatch(getOneUser(user._id));
    };
    get();
  }, []);


  const handleDelete = async() => {
    if(confirmPasswordDeleteProfile === '') return notify(t("Enter the password to confirm account deletion"), "error");
    setLoadingPasswordProfile(true)
    await dispatch(
      updateUserPassword(user._id, {
        oldPassword: confirmPasswordDeleteProfile,
        password: confirmPasswordDeleteProfile,
        status:false
      })
      );
      setLoadingPasswordProfile(false)
  }

  useEffect(()=>{
    if (loadingPasswordProfile === false) {
      if (resPassword && resPassword.success === true) {
        notify("تم  حذف الحساب بنجاح", "success");
        setTimeout(() => {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          navigate("/login");
        }, 1500);
      } else {
        notify(t("The password is incorrect"), "warn");
        setConfirmPasswordDeleteProfile('')
      }
    }
  },[loadingPasswordProfile])


  // console.log('oneUser')
  // console.log(oneUser?.shipping?._id)
  // console.log('oneUser')

  const [loodingShipping, setLoodingShipping] = useState(true);
  const onSelectedShippingMethod = async (e) => {
    if (e.target.value !== 0) {
      setShippingId(e.target.value);
      const get = async (id) => {
        await dispatch(getOneShippingMehod(id, user._id));
        // console.log('-------------------------');
        // console.log(loodingShipping)
        // console.log('-------------------------');
        // setLoodingShipping(false)
        // console.log(loodingShipping)
      };
      get(e.target.value);
      // setShowTable(true)
      // console.log(showTable)
      // console.log(e.target.value)
    } else {
      setShowTable(0);
    }
  };

  // git ome shipping methods
  const oneShippingMethods = useSelector(
    (state) => state.shippingMethodReducer.oneshipping
  );

  const [valueKeyInput, setValuekeyInput] = useState([]);

  useEffect(() => {
    if (oneShippingMethods.length !== 0) {
      // console.log('shipping method ')

      setValuekeyInput(oneShippingMethods.settingsData);

      setwieghtNumber(oneShippingMethods.wieghtNumber);
      setwieghtCost(oneShippingMethods.wieghtCost);
      setwieghtExtraCost(oneShippingMethods.wieghtExtraCost);
      setshippingKey(oneShippingMethods.shippingKey);
      setShippingId(oneShippingMethods._id);

      // setInputElentsValue(oneShippingMethods)
      // console.log("ssssss");

      setShowTable(true);
    }
  }, [oneShippingMethods]);

  //   useEffect(()=>{
  //   console.log('sssssssssssssss')
  //   if(oneShippingMethods){
  //     console.log('-------------------------');
  //     console.log(oneShippingMethods)
  //     console.log('-------------------------');

  // }
  //   },[setLoodingShipping])

  const handleChangeInputKey = (e, key) => {
    // let values = {...valueKeyInput};
    // console.log('values')
    // console.log(values)
    // values[key].value = e.target.value;
    // setValuekeyInput(...valueKeyInput,{key: 'name', value: '', count: 1});
    setValuekeyInput((current) =>
      current.map((obj, index) => {
        if (index == key) {
          return { ...obj, value: e.target.value };
        }

        return obj;
      })
    );

   
  };

  const onSubmit = async () => {
    setLoadingSubmit(true);
    // await dispatch(editShippingMehod(oneShippingMethods._id, {
    //   cost: cost,
    //   settingsData: valueKeyInput
    // }))
    // console.log({
    //   _id: oneShippingMethods._id,
    //   wieghtNumber: wieghtNumber,
    //   wieghtCost: wieghtCost,
    //   wieghtExtraCost: wieghtExtraCost,
    //   shippingKey: shippingKey,
    //   settingsData: valueKeyInput
    // })
    await dispatch(
      updateUserProfile(user._id, {
        shipping: {
          _id: oneShippingMethods._id,
          wieghtNumber: wieghtNumber,
          wieghtCost: wieghtCost,
          wieghtExtraCost: wieghtExtraCost,
          shippingKey: shippingKey,
          settingsData: valueKeyInput,
        },
      })
    );
    setLoadingSubmit(false);
  };

  // const response = useSelector((state) => state.shippingMethodReducer.editshipping)

  useEffect(() => {
    if (loadingSubmit === false) {
      if (res && res.success === true) {
        notify("تم التعديل بنجاح", "success");
        // setTimeout(() => {
        //   navigate("/admin/profile")
        // }, 1000)
      } else {
        notify("فشل في عملية التعديل", "error");
      }
    }
  }, [loadingSubmit]);

  return [
    user,
    handleShow,
    handleClose,
    handleSubmit,
    show,
    firstname,
    lastname,
    mobile,
    email,
    onChangeFirstname,
    onChangeLastname,
    onChangeMobile,
    onChangeEmail,
    changePassword,
    oldpassword,
    newPassword,
    confirmPassword,
    onChangeOldPassword,
    onChangeNewPassword,
    onChangeConfirmPassword,
    allShippingMethods,
    onSelectedShippingMethod,
    showTable,
    webSiteData,
    oneShippingMethods,
    valueKeyInput,
    handleChangeInputKey,
    onSubmit,
    settingsRes,
    oneUser,
    wieghtNumber,
    wieghtCost,
    wieghtExtraCost,
    onChangewieghtCost,
    onChangewieghtNumber,
    onChangewieghtExtraCost,
    shippingId,
    showDelete,
handleShowDelete,
handleCloseDelete,
handleDelete,
confirmPasswordDeleteProfile,
onChangeConfirmPasswordDeleteProfile
  ];
};

export default ProfileHook;

// setValuekeyInput(
//   [...valueKeyInput,
//     {value: value} ,
//   ]
// );
// setValuekeyInput(
//   ...valueKeyInput,
//   valueKeyInput[key] = value,
// );
