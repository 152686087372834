import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllDropShippings,
  updateDropShipping,
} from "../../redux/actions/dropShippingAction";
import { useState } from "react";
import notify from "../useNotification";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
const AdminDropShippingHook = () => {
  const [loadingStatus, setLoadingStatus] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getAllDropShippings());
  }, []);

  const dropShippingList = useSelector(
    (state) => state.dropShippingReducer.allDropShippings
  );

  const handleShowFun = async (id, status) => {
    setLoadingStatus(true);
    await dispatch(updateDropShipping(id, { status: status }));
    setLoadingStatus(false);
  };

  const updateDropShippingRes = useSelector(
    (state) => state.dropShippingReducer.updateDropShippng
  );

  useEffect(() => {
    if (loadingStatus === false) {  
      if (updateDropShippingRes) {
        notify(t("Modified successfully"), "success");
        setTimeout(() => {
          dispatch(getAllDropShippings());
        }, 1000);
      } else {
        notify(t("Failed in the editing process"), "error");
      }
    }
  }, [loadingStatus, updateDropShippingRes]);


  const clickHandler = (id)=>{
    navigate(`/admin/editDropShipping/${id}`)
  }

  return [dropShippingList, handleShowFun,clickHandler];
};

export default AdminDropShippingHook;
