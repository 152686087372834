import React, { useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import add from "../../Images/add.png";
import { useTranslation } from "react-i18next";
import { CompactPicker } from "react-color";
import AdminAddAdsHook from "../../hook/admin/AdminAddAdsHook";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import MultiImageInput from "react-multiple-image-input";

const AdminAddAds = () => {
  const { t } = useTranslation();
  const [
    title,
    key,
    showPicker,
    color,
    handleChaneComplete,
    handleSubmit,
    onChangeTitle,
    onChangeKey,
    onChangePicker,
    check,
    page,
    onChangePage,
    showSecondPicker,
    secondaryColor,
    handleChaneCompleteSecond,
    onChangeSecondPicker,
    link,
    onChangeLink,
    onChangeLink2,
    link2,
    images,
    setImages,
    user,
    allStoresResponse,
    changeStore,
  ] = AdminAddAdsHook();
  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  return (
    <div>
      <Helmet>
        <title>{t("add an ads image")}</title>
      </Helmet>
      <Row className="justify-content-start ">
        <div className="admin-content-text pb-4">
          {t("add a new ads image")}
        </div>
        <Col sm="6">
          <div>
            <div className="mt-3">
              <MultiImageInput
                images={images}
                setImages={setImages}
                theme="light"
                max={2}
                allowCrop={false}
              />
            </div>
          </div>
          <label htmlFor="title" className="d-block mt-2 ">
            {" "}
            {t("address")}{" "}
          </label>

          <input
            id="title"
            onChange={onChangeTitle}
            value={title}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("address")}
          />

          <label htmlFor="link" className="d-block mt-2 ">
            {" "}
            {t("ads link")}{" "}
          </label>
          <input
            id="link"
            value={link}
            onChange={onChangeLink}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("Link")}
          />

          <label htmlFor="link2" className="d-block mt-2 ">
            {" "}
            {t("ads link")}
            {" 2"}
          </label>
          <input
            id="link2"
            value={link2}
            onChange={onChangeLink2}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("Link")}
          />

          {/* <input
            onChange={onChangeKey}
            value={key}
            type="number"
            className="input-form d-block mt-3 px-3"
            placeholder="الرمز"
        /> */}
          <label htmlFor="key" className="d-block mt-2 ">
            {t("Ad place")}
          </label>

          <select
            value={key}
            onChange={onChangeKey}
            name="key"
            id="key"
            className="select input-form-area mt-2 px-3 "
          >
            <option value={0}>{t("code")}</option>
            <option value={1}>{t("Top")}</option>
            <option value={2}>{t("Down")}</option>
          </select>

          {user?.role === "superAdmin" ? (
            <>
              <label htmlFor="type" className="d-block mt-2 ">
                {" "}
                {t("select store")}{" "}
              </label>
              <select
                id="select-store"
                name="select-store"
                className="select input-form-area  mt-3 px-2"
                onChange={changeStore}
              >
                <option value="">{t("select store")}</option>
                {allStoresResponse
                  .filter((el) => el._id !== user?.storeId)
                  .map((store) => (
                    <option className="fw-bold text-black" value={store._id}>
                      {store.name}
                    </option>
                  ))}
              </select>
            </>
          ) : null}

          <label htmlFor="page" className="d-block mt-2 ">
            {" "}
            {t("page")}{" "}
          </label>

          <input
            id="page"
            onChange={onChangePage}
            value={page}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("page")}
            disabled
          />

          <div className="text-form mt-3 ">{t("Main background color")}</div>
          <div className="mt-1 d-flex">
            {color && (
              <div
                className="color ms-2 border  mt-1"
                style={{ backgroundColor: color }}
              ></div>
            )}
            <img
              src={add}
              alt=""
              width="30px"
              height="35px"
              style={{ cursor: "pointer" }}
              onClick={onChangePicker}
            />
            {showPicker === true ? (
              <CompactPicker onChangeComplete={handleChaneComplete} />
            ) : null}
          </div>
          <div className="text-form mt-3 ">{t("sub background color")}</div>
          <div className="mt-1 d-flex">
            {secondaryColor && (
              <div
                className="color ms-2 border  mt-1"
                style={{ backgroundColor: secondaryColor }}
              ></div>
            )}
            <img
              src={add}
              alt=""
              width="30px"
              height="35px"
              style={{ cursor: "pointer" }}
              onClick={onChangeSecondPicker}
            />
            {showSecondPicker === true ? (
              <CompactPicker onChangeComplete={handleChaneCompleteSecond} />
            ) : null}
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm="8" className="d-flex justify-content-end ">
          {check == true ? (
            <Spinner animation="border" variant="primary" />
          ) : (
            <button
              onClick={handleSubmit}
              className="btn-save d-inline mt-2 "
              style={{ backgroundColor: settingsRes?.data?.primaryColor }}
            >
              {t("save")}
            </button>
          )}
        </Col>
      </Row>

      <ToastContainer />
    </div>
  );
};

export default AdminAddAds;
