import {
  CREATE_NEW_SLIDER,
  GET_ALL_SLIDER,
  DELETE_SLIDER,
  UPDATE_SLIDER,
  GET_ONE_SLIDER,
} from "../type";
import { useInsertDataWithImage } from "../../hooks/useInsertData";
import { useGetData } from "../../hooks/useGetData";
import useDeleteData from "../../hooks/useDeleteData";
import { useUpdateDataWithImage } from "../../hooks/useUpdateData";

export const createNewSlider = (formData) => async (dispatch) => {
  try {
    const response = await useInsertDataWithImage(`/api/v1/slider`, formData);

    dispatch({
      type: CREATE_NEW_SLIDER,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: CREATE_NEW_SLIDER,
      payload: error,
    });
  }
};

export const getAllSlider = (storeId) => async (dispatch) => {
  try {
    const response = await useGetData(`/api/v1/slider?storeId=${storeId}`);

    dispatch({
      type: GET_ALL_SLIDER,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_SLIDER,
      payload: error,
    });
  }
};

export const deleteSlider = (id) => async (dispatch) => {
  try {
    const response = await useDeleteData(`/api/v1/slider/${id}`);

    dispatch({
      type: DELETE_SLIDER,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: DELETE_SLIDER,
      payload: error,
    });
  }
};

export const updateSlider = (id, formData) => async (dispatch) => {
  try {
    const response = await useUpdateDataWithImage(
      `/api/v1/slider/${id}`,
      formData
    );

    dispatch({
      type: UPDATE_SLIDER,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_SLIDER,
      payload: error,
    });
  }
};

export const getOneSlider = (id) => async (dispatch) => {
  try {
    const response = await useGetData(`/api/v1/slider/${id}`);

    dispatch({
      type: GET_ONE_SLIDER,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_ONE_SLIDER,
      payload: error,
    });
  }
};
