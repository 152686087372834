import React, { useEffect, useState } from "react";
import {
  createCategory,
  updateCategory,
} from "../../redux/actions/categoryAction";
import { useDispatch, useSelector } from "react-redux";
import notify from "../../hook/useNotification";
import avatar from "../../Images/avatar.png";
import { getAllCategories } from "../../redux/actions/categoryAction";
import { deleteCategory } from "../../redux/actions/categoryAction";
import { useNavigate } from "react-router-dom";
import { getSettings } from "../../redux/actions/settingsAcions";
import getStoreNameFromUrl from "../../Components/Utilities/getStoreNameFromUrl";
const storeName = getStoreNameFromUrl();

const AddCatHook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [img, setImg] = useState(avatar);
  const [nameAr, setNameAr] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isPress, setIsPress] = useState(false);
  const [showSelectStoreComponent, setShowSelectStoreComponent] =
    useState(false);
  const [showAddComponnet, setShowAddComponent] = useState(true);

  const [show, setShow] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const [categoryId, setCategryId] = useState("");
  const [selectedStore, setSelectedStore] = useState("");

  const handleClose = () => setShow(false);

  const handleShow = (id) => {
    setCategryId(id);
    setShow(true);
  };

  const user = JSON.parse(localStorage.getItem("user"));

  const handleDelete = async () => {
    await dispatch(deleteCategory(categoryId));
    notify("تم الحذف بنجاح", "success");
    setShow(false);
    setTimeout(() => {
      // navigator('/admin/categories')
      window.location.reload(false);
    }, 1000);
  };

  const handleShowFun = async (id, status) => {
    setLoading(true);
    await dispatch(
      updateCategory(id, {
        status: status,
      })
    );
    setLoading(false);
  };

  const categoryRes = useSelector((state) => state.allCategory.updateCategory);

  //all store response
  const allStoresResponse = useSelector((state) => state.allStores.allStores);

  //get response store
  const storeIdRes = useSelector(
    (state) => state.allStores.selectStoreBySuperAdmin
  );
  const clickHandler = (id) => {
    navigate(`/admin/editCategory/${id}`);
  };

  const res = useSelector((state) => state.allCategory.category);
  const categories = useSelector((state) => state.allCategory.categories);

  const onChangeNameAr = (e) => {
    setNameAr(e.target.value);
  };
  const onChangeNameEn = (e) => {
    setNameEn(e.target.value);
  };

  //on change store
  const changeStore = (e) => {
    console.log("e.target.value");
    console.log(e.target.value);
    setSelectedStore(e.target.value);
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0])
      setImg(URL.createObjectURL(event.target.files[0]));
    setSelectedFile(event.target.files[0]);
  };
  const handleSubmit = async (event) => {
    if (nameAr === "" || nameEn === "" || selectedFile === null) {
      notify("من فضلك اكمل البيانات", "warn");

      return;
    }
    if (user?.role === "superAdmin" && selectedStore === "") {
      notify("من فضلك تأكد من اختيار المتجر", "error");
      return;
    }
    event.preventDefault();
    const formData = new FormData();
    formData.append("nameAr", nameAr);
    formData.append("nameEn", nameEn);
    formData.append("image", selectedFile);

    formData.append(
      "storeId",
      user?.role === "superAdmin" ? selectedStore : user?.storeId
    );

    setIsPress(true);
    await dispatch(createCategory(formData));
    setLoading(false);
  };

  // console.log("storeIdRes");
  // console.log(storeIdRes);
  useEffect(() => {
    if (user.role === "superAdmin") {
      setShowSelectStoreComponent(true);
      if (storeIdRes !== "") {
        dispatch(getAllCategories(undefined, storeIdRes,'admin'));
        setShowAddComponent(true);
      } else {
        setShowAddComponent(false);
        setAllCategories([]);
      }
    } else dispatch(getAllCategories(undefined, user?.storeId,'admin'));
  }, [dispatch, storeIdRes, user.role, user?.storeId]);

  useEffect(() => {
    if (categories) {
      setAllCategories(categories);
    }
  }, [categories]);

  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  useEffect(() => {
    if (loading === false) {
      setImg(avatar);
      setNameAr("");
      setNameEn("");
      setSelectedFile(null);
      setLoading(true);
      setTimeout(() => setIsPress(false), 1000);

      if (res.status === 201) {
        notify("تم الاضافة بنجاح", "success");
      } else if (categoryRes) {
        notify("تم التعديل بنجاح", "success");
        setTimeout(() => {
          if (storeIdRes !== "") {
            dispatch(getAllCategories(undefined, storeIdRes,'admin'));
          } else {
            dispatch(getAllCategories(undefined, user?.storeId,'admin'));
          }
        }, 1000);
      } else {
        notify("فشل في عملية الاضافه", "error");
      }
    }
  }, [loading]);

  return [
    img,
    nameAr,
    nameEn,
    isPress,
    loading,
    handleSubmit,
    onImageChange,
    onChangeNameAr,
    onChangeNameEn,
    allCategories,
    show,
    handleShow,
    handleClose,
    handleDelete,
    clickHandler,
    handleShowFun,
    settingsRes,
    showSelectStoreComponent,
    showAddComponnet,
    allStoresResponse,
    changeStore,
    user,
  ];
};

export default AddCatHook;
