import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import UnopDropdown from "unop-react-dropdown";
import CatPageHook from "../../hook/category/CatPageHook";

import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";

const CategoryHeader = () => {
  const handler = () => { };
  const [category, loading, pageCount, getPage] = CatPageHook();
  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  const { t } = useTranslation();
  return (
    <div
      className="cat-header"
      style={{ backgroundColor: settingsRes?.data?.primaryColor }}
    >
      <Container>
        <Row>
          <Col className="d-flex justify-content-center flex-wrap" style={{ overflow: 'hidden', height: '40px' }}>
            <Link
              to={`/allcategory`}
              style={{ textDecoration: "none", color: "black" }}
            >
              <div className="cat-text-header headerFontSize">{t("All")}</div>
            </Link>

            {category
              ? category.slice(0, 5).map((el) => {
                return (
                  <Link key={el._id} to={`/category/subCategory/${el._id}`} style={{ textDecoration: 'none' }}>
                    <div key={el._id} className="cat-text-header headerFontSize">{Cookies.get("i18next") === "ar" ? el.nameAr : el.nameEn}</div>

                    {/* <div className="cat-text-header" key={el._id}>
                      <UnopDropdown
                        onAppear={handler}
                        onDisappearStart={handler}
                        trigger={<p> {Cookies.get("i18next") === "ar" ? el.nameAr : el.nameEn} </p>}
                        delay={0}
                        align="CENTER"
                        hover
                      >
                        <div className="card-filter">
                          {el.subCategory.map((item) => {
                            return (
                              <a
                                key={item._id}
                                href={`/products/subcategory/${item._id}`}
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                }}
                              >
                                <div className="border-bottom card-filter-item">
                                  {Cookies.get("i18next") == "ar" ? item.nameAr : item.nameEn}

                                </div>
                              </a>
                            );
                          })}
                        </div>
                      </UnopDropdown>
                    </div> */}
                  </Link>
                );
              })
              : null}


          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CategoryHeader;
