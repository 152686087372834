import {
  ALL_USERS,
  GET_ONE_USER,
  UPDATE_USER,
  ADD_USER_BY_ADMIN,
  GET_ONE_USER_TOKEN,
  ALL_VENDORS,
  SHOWSlELECTSTORECOMPONENT
} from "../type";
import { useGetDataToken } from "../../hooks/useGetData";
import { useUpdateData } from "../../hooks/useUpdateData";
import { useInsertData } from "../../hooks/useInsertData";

//get All users
export const getAllUsers = (storeId) => async (dispatch) => {
  try {
    const response = await useGetDataToken(`/api/v1/users?storeId=${storeId} `);

    dispatch({
      type: ALL_USERS,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: ALL_USERS,
      payload: error,
    });
  }
};

// get one user
export const getOneUser = (id) => async (dispatch) => {
  try {
    const response = await useGetDataToken(`/api/v1/users/${id}`);

    dispatch({
      type: GET_ONE_USER,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_ONE_USER,
      payload: error,
    });
  }
};

// get one user with new token
export const getOneUserWithToken = (id) => async (dispatch) => {
  try {
    const response = await useGetDataToken(
      `/api/v1/users/refreshUserToken/${id}`
    );

    dispatch({
      type: GET_ONE_USER_TOKEN,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_ONE_USER_TOKEN,
      payload: error,
    });
  }
};
// add user
export const addUser = (body) => async (dispatch) => {
  try {
    const response = await useInsertData(`/api/v1/users`, body);
    dispatch({
      type: ADD_USER_BY_ADMIN,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: ADD_USER_BY_ADMIN,
      payload: error.response,
    });
  }
};

// update user
export const updateUser = (id, body) => async (dispatch) => {
  try {
    const response = await useUpdateData(`/api/v1/users/${id}`, body);

    dispatch({
      type: UPDATE_USER,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_USER,
      payload: error,
    });
  }
};

// get all vendors
export const getAllVendors = (storeId) => async (dispatch) => {
  console.log("storeId");
  console.log(storeId);
  try {
    const response = await useGetDataToken(
      `/api/v1/users/admin/vendor/getAllVendors?storeId=${storeId}`
    );

    dispatch({
      type: ALL_VENDORS,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: ALL_VENDORS,
      payload: error,
    });
  }
};

export const showSelectStoreComponent=()=>(dispatch) =>{
  dispatch({ type: SHOWSlELECTSTORECOMPONENT, payload:true });
}
