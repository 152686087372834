import { useEffect } from "react";
import { useDispatch , useSelector } from "react-redux";
import {getAllVendorProductsById} from "../../redux/actions/vendorActions"


const ViewProductVendorHook = () => {
const dispatch = useDispatch()
const vendor = JSON.parse(localStorage.getItem("user"));
const productsByVendorId = useSelector((state)=>state.vendorReducer.getProductsByVendorId)
    useEffect(()=>{
        dispatch(getAllVendorProductsById(vendor._id))
    },[])


    return [productsByVendorId]
}

export default ViewProductVendorHook