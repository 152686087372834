import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import CategoryHeader from "../../Components/Category/CategoryHeader";
import CardProductsContainer from "../../Components/Products/CardProductsContainer";
import LoadingSpinner from "../../Components/Utilities/LoadingSpinner";
import Pagination from "../../Components/Utilities/Pagination";
import ProductsResults from "../../Components/Utilities/ProductsResults";
import SideFilter from "../../Components/Utilities/SideFilter";
import ViewSearchProductHooks from "../../hook/product/ViewSearchProductHooks";

const ShopProductPage = () => {
  const [
    items,
    pagination,
    onPress,
    getProduct,
    result,
    ,
    selectedCategorySearch,
    selectedBrandSearch,
    search,
    onChangeSetMinPrice,
    onChangeSetMaxPrice,
    ,
    ,
    loadingSpinner,
  ] = ViewSearchProductHooks("");

  if (pagination) var pageCount = pagination;
  else pageCount = 0;

  return (
    <div >
      <CategoryHeader />      
      <Container>
        <ProductsResults onClick={getProduct} title={`${result} نتيجة بحث`} />
        <Row className="d-flex">
          <Col className="d-flex" md="2" sm="2">
            <SideFilter
              props={{
                selectedCategorySearch,
                selectedBrandSearch,
                search,
                onChangeSetMinPrice,
                onChangeSetMaxPrice,
              }}
            />
          </Col>
          <Col md="10" sm="10" xs="12">
            {loadingSpinner === true ? (
              <LoadingSpinner />
            ) : (
              <CardProductsContainer products={items} title="" btntitle="" />
            )}{" "}
          </Col>
        </Row>
        <Pagination pageCount={pageCount} onPress={onPress} />
      </Container>
    </div>
  );
};

export default ShopProductPage;