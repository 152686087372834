import  { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getOneProduct,
  getSimilarProducts,
} from "../../redux/actions/productsAction";



const ViewProductDetails = (id) => {
  const dispatch = useDispatch();

  const [productItem,setProductItem] = useState("")
  const [loading,setLoading] = useState(true)

  // const [images,setImages] = useState([])
  


  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true)
    dispatch(getOneProduct(id));
    setLoading(false)
  }, [dispatch, id]);


  const oneProduct = useSelector((state) => state.allProduct.oneProduct);
  const similarProd = useSelector((state) => state.allProduct.similarProducts);
  const settingsRes = useSelector((state) => state.settingsReducers.settings);



  useEffect(() => {
if(loading === false){
  if (oneProduct && oneProduct.nameAr) {
    setProductItem(oneProduct)
    let titleAr = settingsRes?.data?.titleAr;
    let itemName = ''
    itemName = oneProduct.nameAr;
    document.title = ` ${titleAr} -  ${itemName || ''}`;
  }
}
    
  }, [loading, oneProduct, settingsRes?.data?.titleAr]);

  // let item = [];
  // if (oneProduct) item = oneProduct;
  // else item = [];

  useEffect(() => {
    if (oneProduct.category) dispatch(getSimilarProducts(oneProduct.category._id));
  }, [dispatch, oneProduct]);

   let images = [];
  if (oneProduct.images) {
    images = oneProduct.images.map((img) => {
      return { original: img };
    });
  } else {
    images = [{ original: "" }];
  }

  let prod = [];
  if (similarProd && similarProd.data) prod = similarProd.data.slice(0, 4);
  else prod = [];

  return [oneProduct, images, prod , productItem];
};

export default ViewProductDetails;
