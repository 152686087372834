import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategories } from "../../../redux/actions/categoryAction";
import { subCategoryByCatId } from "../../../redux/actions/subCategoryAction";

import {
  getOfferDetailsByOfferId,
  deleteTheProductOffer,
  addProductToOffer,
} from "../../../redux/actions/offersAction";
import notify from "../../useNotification";
import { getAllBrand } from "../../../redux/actions/brandAction";
import { getAllUsers } from "../../../redux/actions/usersActions";
import { getAllProductsHasNoOfferAndFilter } from "../../../redux/actions/productsAction";

const OfferDetailsHook = (id) => {
  const [offerProductId, setOfferProductId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [ProductOfferInfoModal, setProductOfferInfoModal] = useState(false);
  const [categoryId, setCategoryId] = useState(0);
  const [selectedsubId, setSelectedsubId] = useState(0);
  const [brandId, setBrandId] = useState(0);
  const [vedorId, setvedorId] = useState("0");
  const [ProductsModal, setProdustModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [offerValue, setOfferValue] = useState("");
  const [offerType, setOfferType] = useState("");
  const [loadingSpinnerProduct, setLoadingSpinnerProducts] = useState(true);
  const [loadingSpinnerOfferDetails, setLoadingSpinnerOfferDetails] =
    useState(true);
  const [loadingAddProductsToOffer, setLoadingAddProductsToOffer] =
    useState(true);

  const onChangeOfferValue = (e) => {
    e.persist();
    setOfferValue(e.target.value);
  };
  const onChangeOfferType = (e) => {
    e.persist();
    setOfferType(e.target.value);
  };

  const onChangeSelectCategory = async (e) => {
    setCategoryId(e.target.value);
    if (e.target.value != 0) {
      await dispatch(subCategoryByCatId(e.target.value));
    }
  };

  const onChangeSelectedSubCategory = (e) => {
    setSelectedsubId(e.target.value);
  };

  const onSelectBrand = (e) => {
    setBrandId(e.target.value);
  };

  // onchange vendor
  const onChangeVendor = (e) => {
    setvedorId(e.target.value);
  };

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    const get = async () => {
      dispatch(getOfferDetailsByOfferId(id));
      dispatch(getAllCategories(100, user?.storeId));
      dispatch(getAllBrand(100, user?.storeId));
      dispatch(getAllUsers(user?.storeId));
    };
    setLoadingSpinnerOfferDetails(true);
    get();
    setLoadingSpinnerOfferDetails(false);
  }, [dispatch, id]);

  const offerDetailsResponse = useSelector(
    (state) => state.offersReducers.offerDetailsByOfferId
  );

  //get settings
  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  // get aLL Sub category by category id (response)
  const subCategoryByCat = useSelector(
    (state) => state.subCategory.subCategoryByCat
  );

  // get brands response
  const allBrandResponse = useSelector((state) => state.allBrand.brand);

  // get User Logged
  const user = JSON.parse(localStorage.getItem("user"));

  // get WebSite Data
  const webSiteData = useSelector(
    (state) => state.settingsReducers.webSiteData
  );

  // get all vendor
  const getAllUser = useSelector((state) => state.usersReducers.allUser).filter(
    (el) => el.role === "vendor"
  );

  // set vendor id
  useEffect(() => {
    if (webSiteData && webSiteData.MULTI_VENDOR == "false") {
      setvedorId(webSiteData.DEFAULT_VENDOR);
    }
  }, [webSiteData]);

  useEffect(() => {
    if (user && user.role == "vendor") {
      setvedorId(user._id);
    }
  }, [user]);

  // ************************ delete the offer from the product ********************************************
  const showDeleteModal = (id) => {
    setOfferProductId(id);
    setDeleteModal(true);
  };

  // close modal before delete
  const closeDeleteModal = () => {
    setDeleteModal(false);
  };

  // handele delete the offer
  const DeleteOfferProductDetail = async () => {
    await dispatch(deleteTheProductOffer(offerProductId));
    setDeleteModal(false);
    setTimeout(async () => {
      notify(t("Delete successfully"), "success");
      await dispatch(getOfferDetailsByOfferId(id));
    }, 1000);
  };
  //************************************************************************************************************* */
  //  ***************************  select info product category  , sub , brand  , vendor    *********************************** */
  const showProductOfferInfoModal = async () => {
    setProductOfferInfoModal(true);
  };

  const AllCategoryResponse = useSelector(
    (state) => state.allCategory.category
  );

  // close product info modal before delete
  const closeProductOfferInfoModal = () => {
    setProductOfferInfoModal(false);
  };

  // show products Modal to select one or more product
  const showProductsModal = async () => {
    if (vedorId == "0") {
      return notify(t("select vendor name"), "error");
    } else {
      setProdustModal(true);
      setProductOfferInfoModal(false);
      setLoadingSpinnerProducts(true);
      await dispatch(
        getAllProductsHasNoOfferAndFilter(
          categoryId,
          selectedsubId,
          brandId,
          vedorId
        )
      );
    }
    setLoadingSpinnerProducts(false);
  };

  // response of All Products that Filtered and Has No Offer
  const allProductsFilteredResponse = useSelector(
    (state) => state.allProduct.allProductsNoOfferAndFilter
  );

  // close products show Modal
  const closeProductsModal = () => {
    setProdustModal(false);
  };

  // **************************** selected rows ***********************
  const handleRowSelected = (state) => {
    setSelectedRows(state.selectedRows);
  };

  const onSubmitSelectedProducts = async () => {
    if (selectedRows.length === 0) {
      return notify(t("Choose the products"), "error");
    } else if (offerValue <= 0 || offerValue === "") {
      return notify(t("Enter the discount value"), "error");
    } else if (offerType === "" || offerType === "0") {
      return notify(t("Select the type of discount value"), "error");
    } else {
      try {
        setLoadingAddProductsToOffer(true);
        const promises = await selectedRows.map(async (el) => {
          return await dispatch(
            addProductToOffer({
              product: el._id,
              offerId: id,
              type: offerType,
              value: offerValue,
              storeId: user?.storeId,
            })
          );
        });
        await Promise.all(promises);
        setLoadingAddProductsToOffer(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (loadingAddProductsToOffer === false) {
      const getAllOfferDetails = async () => {
        await dispatch(getOfferDetailsByOfferId(id));
      };
      getAllOfferDetails();
      setTimeout(() => {
        setProdustModal(false);
        notify(t("Products have been added to Offer successfully"), "success");
      }, 1000);
    }
  }, [dispatch, id, loadingAddProductsToOffer, selectedRows.length, t]);

  return [
    offerDetailsResponse,
    settingsRes,
    deleteModal,
    showDeleteModal,
    closeDeleteModal,
    DeleteOfferProductDetail,
    ProductOfferInfoModal,
    showProductOfferInfoModal,
    closeProductOfferInfoModal,
    showProductsModal,
    onChangeSelectCategory,
    AllCategoryResponse,
    onChangeSelectedSubCategory,
    subCategoryByCat,
    onSelectBrand,
    allBrandResponse,
    user,
    webSiteData,
    onChangeVendor,
    getAllUser,
    ProductsModal,
    allProductsFilteredResponse,
    closeProductsModal,
    onSubmitSelectedProducts,
    categoryId,
    selectedsubId,
    brandId,
    vedorId,
    handleRowSelected,
    offerValue,
    offerType,
    onChangeOfferValue,
    onChangeOfferType,
    loadingSpinnerProduct,
    loadingSpinnerOfferDetails,
  ];
};

export default OfferDetailsHook;
