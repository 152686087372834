import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllBrands,
  getAllBrandPage,
  updateBrands,
} from "../../redux/actions/brandAction";
import { getSettings } from "../../redux/actions/settingsAcions";
import notify from "../../hook/useNotification";
import { useNavigate } from "react-router-dom";

const BrandPageHook = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const [showSelectStoreComponent, setShowSelectStoreComponent] =
    useState(false);
  const [showAddComponnet, setShowAddComponent] = useState(true);
  const [selectedStore, setSelectedStore] = useState("");
  const [brands, setBrands] = useState([]);

  //on change store
  const changeStore = (e) => {
    console.log("e.target.value");
    console.log(e.target.value);
    setSelectedStore(e.target.value);
  };

  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  //get response store
  const storeIdRes = useSelector(
    (state) => state.allStores.selectStoreBySuperAdmin
  );

  //all store response
  const allStoresResponse = useSelector((state) => state.allStores.allStores);

  useEffect(() => {
    if (user.role === "superAdmin") {
      setShowSelectStoreComponent(true);
      if (storeIdRes !== "") {
        dispatch(getAllBrands(storeIdRes,'admin'));
        setShowAddComponent(true);
      } else {
        setShowAddComponent(false);
        setBrands([]);
      }
    } else dispatch(getAllBrands(user?.storeId,'admin'));

    window.scrollTo(0, 0);
  }, [dispatch, storeIdRes, user.role, user?.storeId]);
  const brand = useSelector((state) => state.allBrand.allBrands);
  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  useEffect(() => {
    if (brand) {
      setBrands(brand);
    }
  }, [brand]);

  const handleShowFun = async (id, status) => {
    setLoading(true);
    await dispatch(
      updateBrands(id, {
        status: status,
      })
    );
    setLoading(false);
  };
  const res = useSelector((state) => state.allBrand.updateBrand);

  useEffect(() => {
    if (loading === false) {
      if (res) {
        notify("تم التعديل بنجاح", "success");
        setTimeout(() => {
          if (storeIdRes !== "") {
            dispatch(getAllBrands(storeIdRes,'admin'));
          } else {
            dispatch(getAllBrands(user?.storeId,'admin'));
          }
        }, 1500);
      } else {
        notify("فشل في عملية التعديل", "error");
      }
    }
  }, [loading]);

  const clickHandler = (id) => {
    navigate(`/admin/editBrand/${id}`);
  };

  return [
    brands,
    clickHandler,
    handleShowFun,
    settingsRes,
    showSelectStoreComponent,
    showAddComponnet,
    allStoresResponse,
    changeStore,
    user,
  ];
};

export default BrandPageHook;
