import { useGetDataToken } from "../../hooks/useGetData"
import { useUpdateData } from "../../hooks/useUpdateData"
import { GET_ALL_DROPSHIPPINGS, GET_ONE_DROP_SHIPPING, UPDATE_DROPSHIPPING } from "../type"


// get all dropShippings methods
export const getAllDropShippings = () => async(dispatch) => {
    try {
        const response = await useGetDataToken('/api/v1/dropShippings')
        dispatch({
            type:GET_ALL_DROPSHIPPINGS,
            payload:response
        })
    } catch (error) {
        dispatch({
            type:GET_ALL_DROPSHIPPINGS,
            payload:error.response
        })
    }
}

// edit dropshipping 
export const updateDropShipping = (id,body) => async(dispatch) => {
    try {
        const response = await useUpdateData(`/api/v1/dropShippings/${id}`,body)
        dispatch({
            type:UPDATE_DROPSHIPPING,
            payload:response
        })
        
    } catch (error) {
        dispatch({
            type:UPDATE_DROPSHIPPING,
            payload:error.response
        })
    }
}

// get one DropShipping
export const getOneDropShopping = (id) => async(dispatch) => {
    try {
        const response = await useGetDataToken(`/api/v1/dropShippings/${id}`)
        dispatch({
            type:GET_ONE_DROP_SHIPPING,
            payload:response
        })
    } catch (error) {
        dispatch({
            type:GET_ONE_DROP_SHIPPING,
            payload:error.response
        })
    }
}